import React from "react";
import { useQueries } from "@tanstack/react-query";
import { LatLng, LatLngBounds } from "leaflet";
import { useApiClient } from "./ApiClient";
import { IMessage, IProfileBrief } from "./AppData";
import { createIntegerSequence } from "./AppUtil";

const useProfileBriefsByPosition = ({bounds, tooManySectors, refetchIntervalMs} : { bounds: LatLngBounds, tooManySectors: number, refetchIntervalMs: number }) => {
  const apiClient = useApiClient()

  const ranges = { latSequence: createIntegerSequence(bounds.getSouth(), bounds.getNorth()), lonSequence: createIntegerSequence(bounds.getWest(), bounds.getEast()) }
  const numSectors = ranges.latSequence.length * ranges.lonSequence.length

  let numSectorsExceeded = numSectors > tooManySectors
  let rangeQueries = (
    numSectorsExceeded
      ? []
      : ranges.latSequence
        .map(lat => ranges.lonSequence.map(lon => ({ lat: lat, lon: lon })))
        .flatMap(llRanges =>
          llRanges.map(ll => 
            {
              const sector = ll.lat + ":" + ll.lon
              return ({
                queryKey: ["profileBriefsBySectorGet", sector],
                queryFn: () => apiClient.profileBriefsBySectorGet(sector, 0, 1500),
                refetchOnWindowFocus: true,
                //refetchInterval: 1000 //1 second
                refetchInterval: refetchIntervalMs,
              })
            }
          )
        )
  )

  const startTime = new Date().getTime()
  const markerQueries = useQueries({
    queries: rangeQueries,
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      }
    },    
  })

  var profileBriefsPropsArr = markerQueries.data.filter(d => d !== undefined).flatMap(d => d) as unknown as IProfileBrief[]
  //Only markers within Map bounds
  profileBriefsPropsArr = profileBriefsPropsArr.filter(
    mm => {
      if (mm && mm.lat && mm.lon) {
        let mmLL = new LatLng(mm.lat, mm.lon)
        let contains = bounds.contains(mmLL)
        return contains
      }
      console.log("Error, mm is incompletely defined", mm)
      return false
    }
  );
  
  return { profileBriefs: profileBriefsPropsArr, pending: markerQueries.pending, numSectorsExceeded }
}

const useMessagesByPosition = ({position, sectorRadius, goingBackMs, refetchIntervalMs} : { position: LatLng, sectorRadius: number, goingBackMs: number, refetchIntervalMs: number }) => {
  const apiClient = useApiClient()

  const ranges = { latSequence: createIntegerSequence(position.lat - sectorRadius, position.lat + sectorRadius), lonSequence: createIntegerSequence(position.lng - sectorRadius, position.lng + sectorRadius) }
  const numSectors = ranges.latSequence.length * ranges.lonSequence.length

  let rangeQueries = (
   ranges
    .latSequence
    .map(lat => ranges.lonSequence.map(lon => ({ lat: lat, lon: lon })))
    .flatMap(llRanges =>
      llRanges.map(ll => {
        var sector = ll.lat + ":" + ll.lon
        return ({
          queryKey: ["messagesBySectorGet", sector],
          queryFn: () => apiClient.messagesBySectorGet(sector , goingBackMs),
          refetchOnWindowFocus: true,
          //refetchInterval: 1000 //1 second
          refetchInterval: refetchIntervalMs,
        })
      })
    )
  )

  const startTime = new Date().getTime()
  const markerQueries = useQueries({
    queries: rangeQueries,
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      }
    },      
  })

  var messages = markerQueries.data.filter(d => d !== undefined).flatMap(d => d) as unknown as IMessage[]  
  console.log({messages})
  return { messages, pending: markerQueries.pending }
}


export { useProfileBriefsByPosition, useMessagesByPosition }