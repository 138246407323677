import * as React from 'react';
import { LatLng } from 'leaflet';
import { useApiClient } from './ApiClient';
import { ILocationDevice, useLocationDevice } from './LocationDevice';
import { getRandomCityLatLng } from './LocationRandomData';
import { IProfilePosition } from './AppData';
import { useLocation } from 'react-router-dom';
import { routes } from '../routes'
import { AppStateContext } from './AppState';

export enum LocationReportingType {
  REPORT_DEVICE_LOCATION,
  REPORT_OVERRIDE_LOCATION,
  REPORT_RANDOM_LOCATION
}
export interface ILocationReporting {
  deviceLatLng: LatLng;
  locationDevice?: ILocationDevice,
  overrideLatLng: LatLng,
  setOverrideLatLng(overrideLatLng: LatLng): void,
  hidden: boolean,
  setHidden(hidden: boolean): void,
  reportingType: LocationReportingType,
  setReportingType(reporting: LocationReportingType): void,
  reportingLatLng: LatLng
  toString: Function
  setPauseReporting: (pauseReporting: boolean) => void
}

const useLocationReporting = function () {
  //Exposed Stated
  const [hidden, setHidden] = React.useState(false);
  const [reportingType, setReportingType] = React.useState(LocationReportingType.REPORT_RANDOM_LOCATION);
  const [overrideLatLng, setOverrideLatLng] = React.useState<LatLng>(getRandomCityLatLng());
  const [gpsLatLng, setGpsLatLng] = React.useState<LatLng>(getRandomCityLatLng());
  const [gpsTimestamp, setGpsTimestamp] = React.useState<Date>();
  const [pauseReporting, setPauseReporting] = React.useState(false);

  const appState = React.useContext(AppStateContext)

  //Private states
  const location = useLocation()
  const apiClient = useApiClient()

  const locationDevice = useLocationDevice({
    maximumAge: 15000, //15 seconds
    enableHighAccuracy: true,
    timeout: 10000      //10 seconds
  })

  React.useEffect(() => {
    var o : IProfilePosition = localStorage.getItem("lastLocation") ? JSON.parse("" + localStorage.getItem("lastLocation")) : null
    if (reportingType == LocationReportingType.REPORT_RANDOM_LOCATION && o && o.overrideLat && o.overrideLon) {
      setReportingType(LocationReportingType.REPORT_OVERRIDE_LOCATION)
      setOverrideLatLng(new LatLng(o.overrideLat, o.overrideLon))
    }
  }, [])

  React.useEffect(
    () => {
      if (locationDevice.geolocationPosition?.coords) {
        setGpsLatLng(new LatLng(locationDevice.geolocationPosition?.coords.latitude as number, locationDevice.geolocationPosition?.coords.longitude as number))
      }
      setGpsTimestamp(locationDevice.geolocationPosition?.timestamp ? new Date(locationDevice.geolocationPosition?.timestamp) : undefined)
    },
    [locationDevice]
  )

  React.useEffect(
    () => {
      if (!appState.sessionProfileId) return
      let o: IProfilePosition = {
        profileId: appState.sessionProfileId,
        overrideLat: overrideLatLng.lat,
        overrideLon: overrideLatLng.lng,
        gpsLat: gpsLatLng.lat,
        gpsLon: gpsLatLng.lng,
        gpsTimestamp: gpsTimestamp,
        use: (
          reportingType == LocationReportingType.REPORT_OVERRIDE_LOCATION || locationDevice.error
            ? "OVERRIDE"
            : "DEVICE_GPS"
        ),
        hide: hidden
      }
      if (!pauseReporting && !routes.filter(r => r.needsAuthenticated != true).map(r => r.path).includes(location.pathname)) {
        //console.log("Calling: profilePositionPut", o)
        localStorage.setItem("lastLocation", JSON.stringify(o))
        apiClient.profilePositionPut(o)
          .then(r => {
            //console.log("saved ProfilePosition", r)
          })
          .catch(e => console.log("failed saving ProfilePosition", e))
      }
    },
    [overrideLatLng, gpsLatLng, reportingType, hidden, appState.sessionProfileId]
  )

  const reportingLatLng = (
    (reportingType === LocationReportingType.REPORT_DEVICE_LOCATION && locationDevice.geolocationPosition)
    ? new LatLng(locationDevice.geolocationPosition.coords.latitude, locationDevice.geolocationPosition.coords.longitude)
    : overrideLatLng
  )

  //console.log(reportingLatLng)

  const toString = () => {
    const llToStr = (ll: LatLng) => "(" + ll.lat + ',' + ll.lng + ")"
    const err = locationDevice?.errorMessage ? (", " + locationDevice?.errorMessage) : ""
    const gpsTs = locationDevice.geolocationPosition?.timestamp ? (", " + new Date(locationDevice.geolocationPosition?.timestamp)) : ""
    return "report:" + (reportingType == LocationReportingType.REPORT_DEVICE_LOCATION ? "device" : reportingType == LocationReportingType.REPORT_OVERRIDE_LOCATION ? "overrride" : "random") + "\n" +
      " o:" + llToStr(overrideLatLng) + "\n" +
      " gps:" + (
        locationDevice?.error 
          ? locationDevice?.errorMessage 
          : ("(" + locationDevice.geolocationPosition?.coords.latitude + ", " + locationDevice.geolocationPosition?.coords.longitude + gpsTs + err + ")") + "\n"
      )
  }

  //Handle deviceGps error instead of blindly calling setReportingType
  const setReportingTypeOverride = (type: LocationReportingType) => {
    // if (type == LocationReportingType.REPORT_DEVICE_LOCATION && locationDevice.error) {
    //   throw (locationDevice.error)
    // }
    setReportingType(type)
  }

  const locationReporting: ILocationReporting = { locationDevice, deviceLatLng: gpsLatLng, overrideLatLng, setOverrideLatLng, hidden, setHidden, reportingType, setReportingType: setReportingTypeOverride, reportingLatLng, toString, setPauseReporting }
  //console.log("useLocationReporting Re-rendered", JSON.stringify(locationReporting))
  return locationReporting;
}

export { useLocationReporting }