import { ProfileActionButton } from '@app/Profile/ProfileCardActionButtons';
import { useApiClient } from '@app/Util/ApiClient';
import { Button, Flex, HelperText, Stack, StackItem, TextArea } from '@patternfly/react-core';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { JsxElement } from 'typescript';
import { TextAreaEditor } from '@app/Widgets/TextAreaEditor';
import { AppStateContext } from '@app/Util/AppState';

//import "https://cdnjs.cloudflare.com/ajax/libs/patternfly/3.24.0/css/patternfly.min.css";
//import "https://cdnjs.cloudflare.com/ajax/libs/patternfly/3.24.0/css/patternfly-additions.min.css";


interface IChatEditor {
  focused: boolean
  draftMessage: string
  senderProfileId: string
  recipientProfileId?: string
  onMessageSent: () => void
}

const MessageEditor: React.FunctionComponent<IChatEditor> = ({ focused = true, draftMessage, senderProfileId, recipientProfileId, onMessageSent }) => {
  const QUERY_KEY = 'conversations';
  const MSG_LENGTH_MAX = 4000

  const apiClient = useApiClient();
  
  const [isSending, setIsSending] = React.useState(false)
  const [message, setMessage] = React.useState(draftMessage)
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  const sendMessage = function () {
    //console.log("Sending " + message);
    if (message.trim() == "") return;
    setIsSending(true)
    apiClient.messagePost({ recipientProfileId: recipientProfileId, bodyText: message.trim(), senderProfileId: senderProfileId, tsCreated: new Date() }).then(
      () => {
        setMessage("")
        onMessageSent()    
        setIsSending(false);
      }
    )    
  };

  return (
    <Flex direction={{ default: 'column' }} style={{ padding: "1.5em", margin: "0px" }} className='ChatEditor'>
      <style>
        {`
        chat { resize: none;overflow: hidden;min-height: 300px;max-height: 300px;}
        `}
      </style>
      <Stack>        
        <StackItem isFilled>
          {
            /* <ShamEditor placeholder={<>Send a message</>} /> 
            <LexEditor />
            <SujiEditor />
            */
          }       
          <TextAreaEditor isDisabled={isSending} focused={focused} value={message} onChange={setMessage} maxLength={MSG_LENGTH_MAX} />   
        </StackItem>
        <StackItem>
          <ProfileActionButton disabled={isSending} isLoading={isSending} color="darkblue" spinnerColor="white" text="Send" onClick={sendMessage} />          
        </StackItem>
      </Stack>
    </Flex>
  );
}
export { MessageEditor };
