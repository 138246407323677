import { debounce } from '@patternfly/react-core';
import { useEffect, useMemo, useState } from 'react';

export interface ILocationDevice {
  geolocationPosition?: GeolocationPosition
  error: string|null
  errorMessage: string
  setPauseReflectingGpsLocation: (pause: boolean) => void
}

export const useLocationDevice = (options?: PositionOptions): ILocationDevice => {
  const [geolocationPosition, setGeolocationPosition] = useState<GeolocationPosition>()
  const [error, setError] = useState<"PERMISSION_DENIED"|"POSITION_UNAVAILABLE"|"TIMEOUT"|null>(null)
  const [errorMessage, setErrorMessage] = useState("")  
  const [watchId, setWatchId] = useState<any>(null);
  const [pauseReflectingGpsLocation, setPauseReflectingGpsLocation] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>()

  const hasChangedSignificantly = (g1: GeolocationPosition | undefined, g2: GeolocationPosition) => {
    //One degree of latitude equals approximately 364,000 feet
    let thresh_lat = 364_000.0 / 50.0
    //One-degree of longitude equals 288,200 feet
    let thresh_lon = 288_200.0 / 50.0
    if (g1 === undefined || g2 === undefined) return true
    let lat_diff = Math.abs(g1.coords.latitude - g2.coords.latitude)
    let lon_diff = Math.abs(g1.coords.longitude - g2.coords.longitude)
    
    if (
      (lat_diff > thresh_lat) ||
      (lon_diff > thresh_lon)       
    ) {
      console.log({msg: "Device Location is different", lat_diff, thresh_lat, lon_diff, thresh_lon})
      return true
    }
    console.log({msg: "Device Location is NOT SIGNIFICANTLY different", lat_diff, thresh_lat, lon_diff, thresh_lon})    
    return false;
  }

  const onEvent = (newDeviceGlp: GeolocationPosition) => {
    if (!pauseReflectingGpsLocation) {
      clearTimeout(timerId)
      //More than 10 SECONDS in Milliseconds different
      const REPORT_ATLEAST_EVERY_SECS = 300
      let time_diff = geolocationPosition ? Math.abs(geolocationPosition.timestamp - newDeviceGlp.timestamp) : 0
      if ( hasChangedSignificantly(geolocationPosition, newDeviceGlp) || time_diff > (REPORT_ATLEAST_EVERY_SECS * 1000) ) {        
        setGeolocationPosition(newDeviceGlp)
      } else {
        console.log("Location is not that different, skipping reflection")
      }
      let newTimerId = setTimeout(
        () => {
          setGeolocationPosition(newDeviceGlp)
        },
        REPORT_ATLEAST_EVERY_SECS * 1000
      )
      setTimerId(newTimerId)
      setError(null)
      setErrorMessage("")
    }
  };

  const onError = (error: GeolocationPositionError) => {
    if (!pauseReflectingGpsLocation) {
      console.log("LocationDevice error", error)
      if (error.code == error.PERMISSION_DENIED) {
        setError("PERMISSION_DENIED")      
      } else if (error.code == error.POSITION_UNAVAILABLE) {
        setError("POSITION_UNAVAILABLE")      
      } else if (error.code == error.TIMEOUT) {
        setError("TIMEOUT")      
      } else {
        console.log("THIS SHOULD NEVER HAPPEN!!")
      }      
      setErrorMessage(error.message)
    }
  }

  const onGeolocattionEvent = debounce(onEvent, 5000)
  //const onGeolocattionEvent = onEvent

  useEffect(() => {
    //console.log("Watching geolocation")
    setWatchId(navigator.geolocation.watchPosition(onGeolocattionEvent, onError, options))
    navigator.geolocation.getCurrentPosition(onGeolocattionEvent, onError, options)

    return () => {
      navigator.geolocation.clearWatch(watchId)
      //console.log("Watching geolocation cleared")
    };
  }, []);

  const retVal = useMemo(() => ({
    geolocationPosition,
    error,
    errorMessage,
    setPauseReflectingGpsLocation
  })
    , [
      geolocationPosition,
      error,
      errorMessage,
      setPauseReflectingGpsLocation
    ])

  return retVal;
};
