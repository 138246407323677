
import * as React from 'react';
import { Flex, HelperText, Stack, StackItem, TextArea } from '@patternfly/react-core';


const TextAreaEditor = ({ focused = false, value, onChange, maxLength = 4000, isDisabled = true}: {
  focused: boolean,
  value: string,
  onChange(message: string) : void,
  maxLength: number,
  isDisabled: boolean
}) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  React.useEffect(()=> {
    if (focused) {
      textAreaRef.current?.scrollIntoView({behavior: 'smooth'});
      //frmChatTextArea.current.scrollTo({ top: frmChatTextArea.current.scrollHeight });
      //$('#chat').scrollTop($('#chat')[0].scrollHeight);
    }
  }, [])
  
  return (
    <Flex className="TextAreaEditor" direction={{ default: 'column' }} >
      <style>
        {`
          chat { resize: none;overflow: hidden;min-height: 300px;max-height: 300px;}
        `}
      </style>
      <Stack>        
        <StackItem isFilled>
          <TextArea            
            ref={textAreaRef}
            style={{fontSize: "18px", fontWeight: "bold", border:"4px solid gray"}}
            placeholder='New Message'
            autoResize={true}
            value={value}
            maxLength={maxLength}
            onChange={(_event, value) => onChange(value)}
            resizeOrientation="vertical"
            aria-label="New Message"
            autoFocus={focused}
            isDisabled={isDisabled}
          />          
        </StackItem>
        <StackItem>
          <HelperText>{"(" + value?.length + "/" + maxLength + ")"}</HelperText>
        </StackItem>
      </Stack>
    </Flex>
  );
}
export { TextAreaEditor }