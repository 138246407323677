import * as React from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Checkbox, Dropdown, DropdownItem, DropdownList, Flex, FlexItem, Gallery, GalleryItem, Grid, GridItem, MenuToggle, MenuToggleElement, PageSection, Stack, StackItem, Text, Title, ToggleGroup, ToggleGroupItem } from '@patternfly/react-core';
import { DharmachakraIcon, DnaIcon, EllipsisVIcon, HelpIcon, RegisteredIcon, TrashIcon, UserCogIcon, UserIcon, UsersCogIcon, UsersIcon, WrenchIcon } from '@patternfly/react-icons';
import { Coronavirus, Crop, Diversity1, Grading, HealthAndSafety, Login, MobileOff, Search, SelfImprovement, Swipe, ThumbsUpDown } from '@mui/icons-material';
import {AppStateContext} from '../Util/AppState'
import { useHistory } from 'react-router-dom';
import { AppSpinner } from '@app/Util/AppUtil';

interface ICardList { }

const GamesPage: React.FunctionComponent<ICardList> = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const appState = React.useContext(AppStateContext);
  
  React.useEffect(() => { setIsLoading(false); }, []);

  return (
    <PageSection className='Games'>
      <Title headingLevel="h1" size="lg">
        Games and Interactive Management
      </Title>
      <Text style={{}} component='p'>&nbsp;</Text>
      <Text style={{}} component='p'>Interactive games and community driven management functions</Text>
      <Text style={{}} component='p'>&nbsp;</Text>
      <style>
        {
          `
          /*@media screen and (min-width: 400px) */
          .Games .pf-v5-l-grid.pf-m-all-6-col-on-sm > * {
              --pf-v5-l-grid__item--GridColumnEnd: span 6;
          }    

          .Games .pf-v5-c-card.pf-m-compact {
            height: 100%;
          }

          `
        }
      
      </style>
      {
        isLoading
          ? <AppSpinner/>
          :
          <>
            <Grid               
              hasGutter
              sm={6} md={6} lg={4} xl={3} xl2={2}
            >
              {cardsData.map((data, index) =>
                <GridItem key={data.id}>
                  <AppCard card={data} />
                </GridItem>
              )}
            </Grid>
          </>
      }
    </PageSection>

  )
}
const iconSize = "50px"
interface ICardData {
  id: number
  name: string
  description: string
  url: string
  icon: string | React.ReactElement
}

let id = 0

const cardsData: ICardData[] = [
  {
    id: id++,
    name: 'GPT Buster!',
    description: 'Interact with others to prove who is human.',
    url: "/games/gptbuster",
    icon: <MobileOff fontSize='inherit'/>    
  },
  {
    id: id++,
    name: 'You got Cooties!',
    description: 'Social network game simulating an STD outbreak.',
    url: "/search/map",
    icon: <Coronavirus fontSize='inherit'/>    
  },
  {
    id: id++,
    name: 'Hot! or Not!',
    description: 'Flip through profile images for matcthes near you',
    url: "/search/map",
    icon: <ThumbsUpDown fontSize='inherit'/>    
  },
  {
    id: id++,
    name: 'The Rating Game',
    description:
      'Rate other users profiles and pictures that will be aggregated and provided to their owners',
    url: "/games/ratinggame",
    icon: <Grading fontSize='inherit'/>
  },
  {
    id: id++,
    name: 'Help my Photos',
    description:
      'Make suggested photo croppings on other profile photos',
    url: "/games/photocrop",    
    icon: <Crop fontSize='inherit'/>
  },
  {
    id: id++,
    name: '3 2 1 Identify!',
    description:
      'Categorize photos with others in uncensored chat.',
    url: "/games/photocrop",    
    icon: <Search fontSize='inherit'/>
  },
  {
    id: id++,
    name: 'Bad Boy Dungeon',
    description:
      'Help rehabilitate and release users who have been suspended.',
    url: "/games/photocrop",    
    icon: <Swipe fontSize='inherit'/>
  },
  {
    id: id++,
    name: 'Health Incident Report',
    description:
      'Report an STD or other incident, and anonymously notify others.',
    url: "/games/photocrop",    
    icon: <HealthAndSafety fontSize='inherit'/>
  },
  {
    id: id++,
    name: 'Community Arbitration',
    description:
      'Assess and discuss reported abuses of other users for community review.',
    url: "/games/arbitration",
    icon: <Diversity1 fontSize='inherit'/>
  },
]

const AppCard = ({ card }: { card: ICardData }) => {
  const history = useHistory()

  return (
    <Card 
      isCompact 
      isClickable 
      isSelectable 
      key={card.name} 
      id={card.name.replace(/ /g, '-')}
      onClick={() => history.push(card.url)}
    >
      <CardHeader>
        <div style={{fontSize:iconSize}}>
          {card.icon}
        </div>        
      </CardHeader>
      <CardTitle>{card.name}</CardTitle>
      <CardBody>{card.description}</CardBody>
    </Card>)
}
export { GamesPage };