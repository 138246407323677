import { Bullseye, Button, DataList, Grid, GridItem, Split, SplitItem, Stack, StackItem } from "@patternfly/react-core";
import { DragDropSort, DraggableObject } from "@patternfly/react-drag-drop";
import React, { ReactElement, useEffect } from "react";
import { ProfilePicThumb } from "./ProfilePicThumbs";
import { useHistory } from "react-router-dom";

interface IProfilePicReorderFrame {  
  profilePicIds: string[];
  setProfilePicIds: (profilePicIds: string[]) => void;
  profilePicWidth: number
}
const ProfilePicReorderFrame = ({profilePicIds, setProfilePicIds, profilePicWidth } : IProfilePicReorderFrame) : ReactElement => {
  const history = useHistory()
  // const [items1, setItems1] = React.useState<DraggableObject[]>([
  //   { id: 'with-button-1', content: 'one' },
  //   { id: 'with-button-2', content: 'two' },
  //   { id: 'with-button-3', content: 'three' }
  // ]);
  const items2 = profilePicIds.map(id => ({ 
    id: "" + id, 
    content: 
      <Split hasGutter style={{width:"100%", margin: "1em 0"}}>
        <SplitItem><ProfilePicThumb profilePicId={id} width={profilePicWidth} key={id}/></SplitItem>
        <SplitItem isFilled>
          <Stack>
            <StackItem>98 Views, 67 Likes, 87 Skips<br/><br/><br/></StackItem>
            <StackItem>
              <Split>
                <SplitItem>
                  <Button style={{ fontWeight: "bold", color: "black", backgroundColor: "lightblue"}} onClick={() => {history.push("/profilePicEdit?id=" + id)}}>Crop</Button>
                </SplitItem>
                <SplitItem>
                  <Button style={{ fontWeight: "bold", color: "black"}} onClick={() => {setProfilePicIds(profilePicIds.filter(i => "" + i != (id + "")))}}>Remove</Button>
                </SplitItem>
              </Split>
            </StackItem>
          </Stack>
          
        </SplitItem>
        
      </Split>
  }))

  const items = items2
  const props = {}
  return (
    <DragDropSort
      items={items}
      onDrop={(_, newItems) => {
        setProfilePicIds(newItems.map(i => i.id))
      }}
      variant="DataList"
    >
      <DataList aria-label="draggable data list example" isCompact {...props} />
    </DragDropSort>
  )
  
}

export { ProfilePicReorderFrame }