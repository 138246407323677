import * as React from 'react'
import { ProfileCardLazy } from '../Profile/ProfileCard'
import { useApiClient } from '../Util/ApiClient'
import { IProfile, IProfileBrief, allowedProfileCharacteristics, } from '../Util/AppData'
import { Bullseye, Button, Checkbox, ExpandableSection, Form, FormGroup, Gallery, GalleryItem, Grid, GridItem, PageSection, Spinner } from '@patternfly/react-core'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useHistory } from 'react-router-dom'
import { useNearestMapMarkers } from '../Util/AppDataMapMarker'
import { useIntersectionObserver } from '@uidotdev/usehooks'
import { AppSpinner } from '../Util/AppUtil'
import { ProfileFilter } from './ProfileFilter'
import { useProfileBriefsByPosition } from '@app/Util/AppDataPositionQueries'
import { LatLngBounds } from 'leaflet'
import { LocationReportingContext } from '@app/Util/LocationReportingContext'

//TODO  Implement Inf Scrolling and Filtering 
//https://codesandbox.io/s/infinite-scrolling-useref-g4fgz?file=/src/App.js
const REFETCH_INTERVAL_SECS = 60 * 5

interface IProfilesPage { }
const ProfilesPage: React.FunctionComponent<IProfilesPage> = (props) => {
  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const [filtered, setFiltered] = React.useState(false);
  const [filterChars, setFilterChars] = React.useState<string[]>([])
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const history = useHistory()
  const locationReporting = React.useContext(LocationReportingContext)
  const rll = locationReporting.reportingLatLng

  const bounds = new LatLngBounds([rll.lat -1, rll.lng - 1],[rll.lat + 1, rll.lng + 1])
  const { profileBriefs, numSectorsExceeded, pending } = useProfileBriefsByPosition({bounds, tooManySectors: 20, refetchIntervalMs: REFETCH_INTERVAL_SECS * 1000})

  const [loadMoreElement, entry] = useIntersectionObserver({ threshold: .25, root: null, rootMargin: "0%" });

  const filteredProfileBriefs = 
    filterChars.length > 0
    ? profileBriefs.filter(pb => {
        return filterChars.every(char => pb.profileChars.includes(char))
      }) 
    : profileBriefs
  console.log({"filtered.length" : filteredProfileBriefs.length})

  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      <div style={{margin:"0 1.5em"}}>
        <ProfileFilter filterChars={filterChars} setFilterChars={setFilterChars} filtered={filtered} setFiltered={setFiltered} />      
      </div>
      {/* <>Rendered Search : { JSON.stringify({ts: Date.now(), isFetched, isLoading, dataLe: mapMarkers?.length}) }</> */}
      { 
        pending
        ? <AppSpinner />
        :
        <Grid
          // style={{margin:"20px"}}
          sm={12} md={12} lg={6} xl={4} xl2={3}
          hasGutter
        >       
          {
            filteredProfileBriefs
              .map((pb, i) =>{
                return (
                  <GridItem key={pb.profileId}>
                    <ProfileCardLazy profileId={pb.profileId} profileBrief={pb} showChatProfileButton={true} />
                  </GridItem>
                )
              })
          }
          <GridItem>
            <Bullseye>
              <div ref={loadMoreElement} style={{ textAlign: "center" }}>
                Aren't you particular!<br />
                {/* <Button onClick={() => { !isFetching && fetchNextPage() }}>Load More</Button> */}
              </div>
            </Bullseye>
          </GridItem>

        </Grid>
      }
    </PageSection>
  )
}

export { ProfilesPage };
