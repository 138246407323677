import React from "react"
import { IProfileBrief } from "../../Util/AppData"
import { MapMarkerProfile } from "./MapMarkerProfile"
import { ProfileLocationControlType } from "./MapPage"
import { LatLngBounds, LatLng, Map as LeafletMap } from "leaflet"
import { } from "react-leaflet"

interface IMapMarkerProfilesComponent {
  profileBreifsMap: Map<string, IProfileBrief>, bounds: LatLngBounds, profileLocationControlType: ProfileLocationControlType
}
const MapMarkerProfilesScaled = ({ profileBreifsMap: profileMapMarkersPropsMap, bounds, profileLocationControlType }: IMapMarkerProfilesComponent) => {
  const MAP_MARKERS_SHRINK_THRESH = 32
  const MAP_MARKER_IMG_WIDTH_MIN: number = 16
  const MAP_MARKER_IMG_WIDTH_IDEAL: number = 64
  const MAP_MARKER_IMG_WIDTH_MAX: number = 128
  const MAP_MARKER_BORDER_WIDTH_SCALE: number = 15
  //console.log("eff count", markersWithinMapBounds.length)

  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  const pixarea = vw * vh //2554  * 1080
  const pixAreaPerCount = pixarea / Array.from(profileMapMarkersPropsMap).length
  const CROWDING_MAX_PCT = 0.75
  let scale_x: number = Math.floor(Math.sqrt(pixAreaPerCount)) * CROWDING_MAX_PCT
  scale_x = Math.max(scale_x, MAP_MARKER_IMG_WIDTH_MIN)
  scale_x = Math.min(scale_x, MAP_MARKER_IMG_WIDTH_MAX)
  const borderWidth = Math.floor(scale_x / MAP_MARKER_BORDER_WIDTH_SCALE)
  //For Help Adjusting the Size of UI Markers
  //console.log([vw, vh, pixarea, profileCount, pixAreaPerCount, scale_x, borderWidth])
  //console.log({scale_x, profileCount})
  return (
    <>
      <style> 
        {".markerIcon { border-width:" + borderWidth + "px;width:" + scale_x + "px !important;height:" + scale_x + "px;background-color:#222;}"} 
      </style>      
      {
        Array.from(profileMapMarkersPropsMap.entries()).map((d, i) => d[1]).map((p) => 
          <MapMarkerProfile key={p.profileId} profileMapMarkerProps={p} scale_x={scale_x} mapLocationType={profileLocationControlType} />
        )
      }
    </>
  )
}
export { MapMarkerProfilesScaled }