import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { useApiClient } from './ApiClient'
import { ISettings } from './AppData'

interface IUseAuth {
  isLoggedIn: boolean
  logout: () => void
  refresh: () => void
}

interface IAppState {
  refresh(): void;
  settings: ISettings|undefined
  saveSettings: (mySettings: ISettings) => void  
  auth: IUseAuth,
  sessionProfileId: string|undefined
}
const AppStateContext = React.createContext<IAppState>({} as IAppState)

const useAuth = () => {
  const [refresh1, setRefresh] = React.useState({})
  //useCookie does not listen for state change automatically
  const [cookies, setCookie, removeCookie] = useCookies(['auth'])
  //const [cookies, setCookie, removeCookie] = [[], ()=> {}, (a)=>{}]
  const logout = () => {removeCookie("auth")};
  const refresh = () => { setRefresh({}) }
  const retVal = { isLoggedIn: cookies["auth"] ? true : false, logout, refresh }
  //console.log("rendering useAuth", retVal)
  return retVal as IUseAuth
}

function AppStateContextComponent({children}) {
  const auth = useAuth()
  const apiClient = useApiClient()
  const [sessionProfileId, setSessionProfileId] = React.useState<string>()
  const {data: settings} = useQuery({
    queryKey:["settingsGet"],
    queryFn: apiClient.settingsGet,
    enabled: auth.isLoggedIn,
    staleTime: Infinity
  })

  useEffect(() => { 
    settings && setSessionProfileId(settings.defaultProfileId)    
  }, [settings])

  const {mutate: saveSettings} = useMutation({
    mutationFn: apiClient.settingsPut,
    mutationKey: ["settingsGet"]    
  })

  const contextValue : IAppState = {
    refresh: () => {
      //New state object so that isObject === returns false
      //setRefresh({})
      auth.refresh()
    },
    sessionProfileId,
    auth,
    settings,
    saveSettings
  }

  //console.log("Rerending with AppState.auth", contextValue.auth)
  console.log({sessionProfileId})    
  console.log({settings})    
  
  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  );
}

export {IAppState, AppStateContext, AppStateContextComponent}