import * as React from 'react';
import { IProfile } from '@app/Util/AppData';
import { Bullseye, Button, PageSection, Split, SplitItem, Stack, StackItem } from '@patternfly/react-core';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperRef } from "swiper/react";
import { SwiperClass } from "swiper/react";
import { ProfilePicThumbs } from './ProfilePicThumbs';
import { useApiClient } from '@app/Util/ApiClient';
import { useQuery } from '@tanstack/react-query';

const profilePicCarouselPageUrl = (profileId: string, profilePicId: string, profilePicIds: string[]) => "/profilePicCarousel?profileId=" + profileId + "&id=" + profilePicId + "&ids=" + profilePicIds.join(",");

const ProfilePicCarouselPage: React.FunctionComponent = (props) => {
  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const profileId = "" + queryParams.get("profileId")
  const id = "" + queryParams.get("id")
  let ids = queryParams.get("ids") ? queryParams.get("ids")?.split(",").map((id) => id) : [id]
  if (!ids) ids = [];

  const QUERY_KEY = "PROFILE"
  const apiClient = useApiClient();
  const { status, data : dto, error, isLoading } = useQuery<IProfile, Error>({
    queryKey: [QUERY_KEY, profileId],
    queryFn: () => apiClient.profileGetById(profileId),
    refetchOnWindowFocus: true,
    staleTime: 3600000, //60min      
  });

  const index = ids.findIndex(e => e == id)
  const prevIndex = index - 1
  const hasPrev = prevIndex >= 0
  const nextIndex = index + 1
  const hasNext = nextIndex < ids.length
  const actionNext = () => { if (ids) history.push(profilePicCarouselPageUrl(profileId, ids[nextIndex], ids)); }
  const actionPrev = () => { if (ids) history.push(profilePicCarouselPageUrl(profileId, ids[prevIndex], ids)); }

  const imageUrl = '/api/profile/pic/' + id

  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      <style>
        {".ProfilePicThumbs { text-align:center;}"}
      </style>
      {/* {JSON.stringify({id, ids, index, prevIndex, hasPrev, nextIndex, hasNext})} */}
      <Stack>
        <StackItem isFilled>
          <Split style={{height:"100%"}}>
            <SplitItem style={{}}>
              <Bullseye>
                <Button style={{height:"100%"}} onClick={actionPrev} isDisabled={!hasPrev} >Prev</Button>
              </Bullseye>
            </SplitItem>
            <SplitItem isFilled style={{height:"100%"}}>
              <Stack style={{height:"100%"}}>
                <StackItem>
                  {
                    ids?.length > 1 && dto != undefined && dto != undefined
                      ? <ProfilePicThumbs profile={dto} profilePicIds={ids} width={200} />
                      : <></>
                  }
                </StackItem>
                <StackItem isFilled style={{textAlign:"center", verticalAlign:"middle"}}>
                    <img style={{  width:"auto", maxWidth: "100%", objectFit:"cover"}}src={imageUrl} />
                {/* <img style={{  width:"auto", maxHeight: "100%", objectFit:"cover"}} src={imageUrl} /> */}
                    {/* <BackgroundImage src={images} style={{ width: "100%", height: "100%" }} className='ProfilePicViewImg' /> */}
                </StackItem>
                <StackItem>
                  <Bullseye><Button onClick={() => history.push("/profile?id=" + profileId)}>View Profile</Button></Bullseye>
                </StackItem>
              </Stack>
            </SplitItem>
            <SplitItem>
              <Bullseye>
                <Button style={{height:"100%"}} onClick={actionNext} isDisabled={!hasNext} >Next</Button>
              </Bullseye>
            </SplitItem>
          </Split>
        </StackItem>
      </Stack>
    </PageSection>
  )
}


const AppCarousel = () => {
  const swiperElRef = React.useRef<SwiperRef>(null);

  React.useEffect(() => {

    // // listen for Swiper events using addEventListener
    // swiperElRef.current?.addEventListener('swiperprogress', (e: any) => {
    //   const [swiper, progress] = e.detail;
    //   console.log(progress);
    // });

    // swiperElRef.current?.addEventListener('swiperslidechange', (e) => {
    //   console.log('slide changed');
    // });


  }, []);

  function setup(swiper: SwiperClass): void {
    console.log("Not COnfigured")
    //throw new Error("Function not implemented.");
  }

  return (
    <Swiper
      ref={swiperElRef}
      slides-per-view="3"
      navigation={true}
      pagination={true}
      onSwiper={setup}
    >
      <SwiperSlide>Slide 1</SwiperSlide>
      <SwiperSlide>Slide 2</SwiperSlide>
      <SwiperSlide>Slide 3</SwiperSlide>
      ...
    </Swiper>
  );
};

export { profilePicCarouselPageUrl, ProfilePicCarouselPage }
