import React, { useContext } from 'react'
import { useApiClient } from '../Util/ApiClient'
import { skipToken, useQuery } from '@tanstack/react-query'
import { IProfilePicLike, Like } from '../Util/AppData';
import { PageSection, Text, TextContent } from '@patternfly/react-core';
import { AppSpinner, profilePicSteppedWidthApiUrl } from '../Util/AppUtil';
import { LikesJumpLinks } from './LikesPageLib';
import { ITile, TilesGrid } from '@app/Widgets/TilesGrid';
import { useHistory } from 'react-router-dom';
import { AppStateContext } from '@app/Util/AppState';

const ProfilePicsYouLiked = () => {
  const apiClient = useApiClient();
  const history = useHistory()

  const appState = React.useContext(AppStateContext)
  const sessionProfileId = appState.sessionProfileId

  const { status, data: likes, error, isFetching } = useQuery<IProfilePicLike[], Error>({
    queryKey: ["profilePicLikesGet", sessionProfileId],
    queryFn: sessionProfileId ? () => apiClient.profilePicLikesByLikerGet(sessionProfileId) : skipToken
  });

  const tiles = !likes ? [] : likes.filter(like => like.likeType == Like.LIKE).map((like) => {
    const tile: ITile = { 
      uid: like.profilePicId,
      imageUrlOfWidth:(width) => profilePicSteppedWidthApiUrl(like.profilePicId, width),
      onClick: () => history.push("/profile?id=" + like.profilePicProfileId)
    }
    return tile    
  } )
  if (!likes) return <AppSpinner />
  return (
    <>
      <TextContent><Text component="h2" style={{textAlign:"center", paddingBottom: "1em"}}>Profile Pics You Liked</Text></TextContent>
      {likes.length == 0 && <div style={{textAlign:"center"}}>Beauty is in the eye of the beholder, do you need your vission checked?</div>} 
      <TilesGrid tiles={tiles} requestMore={() => {}} hasNextPage={false} footerText={likes.length ? "Great selections!" : ""}/>
    </>
  )
}

const ProfilePicsYouLikedPage = (props) => {  
  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      {/* <Title style={{textAlign:"center"}} headingLevel="h1" size="lg">Likes</Title> */}
      <LikesJumpLinks />
      <ProfilePicsYouLiked />
    </PageSection>
  )
}

export { ProfilePicsYouLikedPage }