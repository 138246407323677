import React from "react";

const permissionDeniedUiMessage = "You have denied this app from notifications, please reconfigure this app's if you wish to change this."
interface IusePWAPushNotification {
  subscribeForPushNotification: boolean
  applicationServerKey: ArrayBuffer
  onFoundPushSubscription(subscription: PushSubscription): void
}
const usePWAPushNotification = ({ subscribeForPushNotification, applicationServerKey, onFoundPushSubscription }: IusePWAPushNotification) => {
  const [error, setError2] = React.useState("")
  const [status, setStatus2] = React.useState("")
  const [swRegistrations, setSwRegistrations] = React.useState<ServiceWorkerRegistration[]>();

  const setError = (m) => { console.log(m); setError2(m) }
  const setStatus = (m) => { console.log(m); setStatus2(m) }

  const foundSubscriptionStatusMessage = (subscription: PushSubscription) => "Notifications are subscribed on this device." + JSON.stringify([subscription, subscription.getKey("auth")])

  function subscribeForPushNotifications() {
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      const o: PushSubscriptionOptions = { userVisibleOnly: true, applicationServerKey: applicationServerKey }
      serviceWorkerRegistration.pushManager.subscribe(o).then(function (subscription) {

        //return the subscription to the parent component,
        //that should handle saving to to the server
        setError("")
        setStatus(foundSubscriptionStatusMessage(subscription))
        onFoundPushSubscription(subscription)
      })
        .catch(function (e) {
          if (Notification.permission === 'denied') {
            setError(permissionDeniedUiMessage)
          } else {
            setError('Unable to subscribe to push. ' + e);
          }
        });
    });
  }

  React.useEffect(() => {
    // Check if desktop notifications are supported
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      setError("Notifications aren't supported on this device.")
    } else if (Notification.permission === 'denied') {
      setError(permissionDeniedUiMessage);
    } else if (!('PushManager' in window)) {
      setError("Push messaging isn't supported on this device");
    } else if (!swRegistrations) {
      setError("Setting up ServiceWorker")
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        if (registrations && registrations.length > 0) {
          setError("Found ServiceWorkerRegistration[] " + registrations.length)
          setSwRegistrations([...registrations])
        } else {
          setError("Found empty ServiceWorkerRegistration[]")
          navigator.serviceWorker.register("sw.js")
            .then((registration) => { setSwRegistrations([registration]) })
            .catch((e) => setError(e))
        }
      })
    } else {
      setStatus("Getting Service Worker Registration")
      navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        setStatus("Getting Push Manager")
        serviceWorkerRegistration.pushManager.getSubscription().then(function (subscription) {

          if (!subscription) {
            if (subscribeForPushNotification) {
              setStatus("Subscribing for Notifications")
              subscribeForPushNotifications()
            }
            else {
              setStatus("Push Notification is not Subscribed");  //As intended
              setError("")
            }
          } else {
            setStatus(foundSubscriptionStatusMessage(subscription));  //As intended
            setError("")
            onFoundPushSubscription(subscription)
          }
        }).catch(function (err) {
          setStatus("")
          setError('Error during getSubscription()' + err);
        });
      }).catch((e) => {
        setStatus("")
        setError(e)        
      })

    }

  }, [subscribeForPushNotification, swRegistrations]);




  /**
  * Step four: Send the generated subscription object to our server.
  */
  // function sendSubscriptionToServer(subscription : PushSubscription) {
  //   // Get public key and user auth from the subscription object
  //   var key = subscription.getKey ? subscription.getKey('p256dh') : '';
  //   var auth = subscription.getKey ? subscription.getKey('auth') : '';
  //   // This example uses the new fetch API. This is not supported in all
  //   // browsers yet.
  //   const te = new TextEncoder();
  //   return fetch('/pushNotificationSubscription', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       endpoint: subscription.endpoint,
  //       // Take byte[] and turn it into a base64 encoded string suitable for
  //       // POSTing to a server over HTTP
  //       // key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
  //       // auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : ''        
  //       key: key ? btoa(key.toString()) : '',
  //       auth: auth ? btoa(auth.toString()) : ''
  //     })
  //   });
  // }


  console.log("Rendered PWA")
  return { error, status: status }
}

export { usePWAPushNotification }