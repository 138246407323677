import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//import logo from '@patternfly/react-core/src/components/LoginPage/examples/brandImgColor2.svg';                       
import { useApiClient } from '../Util/ApiClient';
import { ActionGroup, Alert, Button, Checkbox, Form, FormGroup, HelperText, Label, PageSection, Text, TextInput, Title } from '@patternfly/react-core';
import { LocationReportingContext } from '../Util/LocationReportingContext';

const RegisterPage: React.FunctionComponent = (props) => {
  const [email, setEmail] = React.useState('')
  const [showSubmit, setShowSubmit] = React.useState(true)
  const [agree, setAgree] = React.useState(false)
  const [statusEl, setStatusEl] = React.useState<ReactElement>()
  const locationReporting = React.useContext(LocationReportingContext)

  useEffect(()=> locationReporting?.setPauseReporting(true))

  const history = useHistory();
  const api = useApiClient();

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const onSubmitClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if(email == "" || agree == false) {      
      let msg = "Email address and Acknoweldgement of data sharing is required to use this application."
      setStatusEl(<Alert variant='danger' title={msg} />)
      return 
    }
    event.preventDefault();
    ////{ email }, //TODO Add Human Proof if neccessary
    setShowSubmit(false)
    setStatusEl(<Alert variant='warning' title="Sending..." />)
    api.get(
      "/api/auth/emailLoginLink?email=" + email,
      (text: String) => {
        let msg = "Please check you email for a login link.  The link will log you in and will continue to work until your session expires or you reset again."
        setStatusEl(<Alert variant='success' title={msg} />)        
        //Failed Login        
      },
      (e) => {
        console.error(e);
      }
    );
  };

  const SiteMessage = ()=>{
    const devMessage = "This site is under active development, open registration may not be available at this time."
    return <Alert variant={'warning'} title={devMessage}/>
  }

  return (
    <PageSection>
      <SiteMessage/>
      <br/>
      <br/>
      <Title headingLevel="h1" size="lg">Register / Login</Title>
      <Form isWidthLimited >
        <FormGroup
          label="Please enter your email address:"
          isRequired
          fieldId="horizontal-form-name"            
        >          
          <HelperText>This is a new or previsouly registered email address.</HelperText>
          <TextInput
            label='email'
            id="email"
            value={email}
            isRequired
            type="text"
            name="email"
            onChange={(e, v) => setEmail(v)}
          />
        </FormGroup>
        <FormGroup label="">
          <Checkbox
            id="agree" 
            isRequired
            value={"I_AGREE"}
            isChecked={agree}
            onClick={() => {setAgree(!agree); setStatusEl(<></>)}}
            label="This is a geolocation based scoial community application.  
            The technology used to support this application requires the use of browser cookies, 
            geolocation infomration sharing, and the logging of identifiers such as email and 
            IP addresses.  I acknowledge and agree to the sharing of this information with 
            this application."
            />            
        </FormGroup>
        {statusEl}
        <ActionGroup>
          { showSubmit && <Button disabled={!agree} onClick={onSubmitClick}>Send Login Link</Button>}
        </ActionGroup>
      </Form>
    </PageSection>
  );
  
};

export { RegisterPage };

