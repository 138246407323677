import * as React from 'react';
import { ThumbsDownIcon, ThumbsUpIcon } from '@patternfly/react-icons';
import { Like } from '@app/Util/AppData';


interface ILikeIcon {
  likeType : Like
  selected: boolean
  style : React.CSSProperties
}
export const LikeIcon = ({likeType: like, selected, style: css} : ILikeIcon) => {
  if (selected) css = {...css, backgroundColor: "transparent"} 
  if (!selected) css = {...css, backgroundColor: "transparent"}

  if (like == Like.LIKE) return <ThumbsUpIcon color="red" style={css} />
  if (like == Like.DISLIKE) return <ThumbsDownIcon color="black" style={css} />
  return <span>&#x1F44D;</span> //Unicode Thumbs Up
  return (
    <div style={{ 
      borderRadius: "1em", 

      border: "1px solid gray",
      // width: "200px", 
      // height: "200px", 
      height: "1em", width: "1em",
      display: "inline-block", 
      justifyContent: "center", 
      alignItems: "center",
      position: "relative"
    }}
    >
    {/* <ThumbsUpIcon color="blue" height=".5em" width=".5em" style={{ margin: ".25em .25em" }} /> */}
    </div>
  )
}
