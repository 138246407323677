import React from 'react'
import { useApiClient } from '../Util/ApiClient'
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query'
import { IProfilePicLike } from '../Util/AppData';
import { Button, Grid, GridItem, PageSection, Text, TextContent } from '@patternfly/react-core';
import { AppSpinner } from '../Util/AppUtil';
import { ProfileCardLazy } from '../Profile/ProfileCard';
import { useHistory } from 'react-router-dom';
import { LikesJumpLinks } from './LikesPageLib';
import { AppStateContext } from '@app/Util/AppState';

const ProfilesLikedYou = () => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const [saveMessage, setSaveMessage] = React.useState("");
  const history = useHistory()

  const appState = React.useContext(AppStateContext)
  const sessionProfileId = appState.sessionProfileId

  const { status, data: likes, error, isFetching } = useQuery<IProfilePicLike[], Error>({
    queryKey: ["profilePicLikedGet", sessionProfileId],
    queryFn: sessionProfileId ? () => apiClient.profilePicLikesByLikedGet(sessionProfileId) : skipToken
  });


  if (!likes) return <AppSpinner />
  return (
    <>
      {/* {JSON.stringify(likes)} */}
      <TextContent><Text component="h2" style={{textAlign:"center", paddingBottom: "1em"}}>Profiles Liked Your Pics</Text></TextContent>
      {likes.length == 0 && <div style={{textAlign:"center"}}>Don't worry.  Some day your prince will come.</div>} 
      <Grid 
        sm={12} md={12} lg={6} xl={4} xl2={3}
        hasGutter
      >
        {likes.map((item,pos) => {
          return (
            <GridItem key={item.likerProfileId}>
              <ProfileCardLazy profileId={item.likerProfileId} showAction={false} showDescription={false} >
                
              </ProfileCardLazy>              
            </GridItem>
          )
          
        })}
      </Grid>
    </>
  )
}

const ProfilesLikedYourPicsPage = (props) => {  
  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      {/* <Title style={{textAlign:"center"}} headingLevel="h1" size="lg">Likes</Title> */}
      <LikesJumpLinks />
      <ProfilesLikedYou />
    </PageSection>
  )
}

export { ProfilesLikedYourPicsPage }