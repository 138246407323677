import { useApiClient } from '../Util/ApiClient';
import { IMessage } from '../Util/AppData';
import { Divider, NavItemSeparator, PageSection, Stack, StackItem, Text, Title } from '@patternfly/react-core';
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import './ConversationsListPage.css'
import { ConversationsListPageItem } from './ConversationsListPageItem';
import { AppStateContext } from '@app/Util/AppState';
import { AppSpinner } from '@app/Util/AppUtil';

interface ICardList { }

const ConversationsListPage: React.FunctionComponent<ICardList> = (props) => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const [saveMessage, setSaveMessage] = React.useState("");
  const appState = React.useContext(AppStateContext)

  const sessionProfileId = appState.sessionProfileId
  const { status, data : messages, error, isFetching } = useQuery<IMessage[], Error>({
    queryKey: ['messagesMostRecentWith', sessionProfileId], 
    queryFn: sessionProfileId ? () => apiClient.messagesMostRecentWith(sessionProfileId) : skipToken, 
    refetchInterval: (query) => {       
      //TODO Implement backoff function
      return 180000
    },
    refetchOnMount: 'always'
  });

  return (
    <PageSection className="ConversationsListPage" height="100%" width="100%" style={{position:"relative",margin:0, padding:"1em"}}>
      {/* <Title headingLevel="h1" size="lg">
        Conversations
      </Title>      
      <Divider /> */
      }
      <Text component='h2'>Your Conversations</Text>
      <Divider />
      {
        sessionProfileId == null || isFetching || messages === undefined
          ? <AppSpinner />
          :
          <Stack className='Conversations'>
            {
              !messages
              ? <StackItem style={{color: "white"}}>No conversations found</StackItem>
              : messages.map(m => {
                  //console.log(profile);
                  //console.log(message);                    
                  return (
                    <StackItem className="Item" key={m.id}>
                      <ConversationsListPageItem myProfileId={sessionProfileId} message={m} />
                      {/* <Divider inset={{default:"insetNone"}}/> */}
                    </StackItem>
                  )
                })
            }
          </Stack>
      }
    </PageSection>

  )
}

export { ConversationsListPage };
