import { IMessage } from '@app/Util/AppData';
import React, { CSSProperties } from 'react';
import { format, formatISO9075, formatDistance, formatRelative, subDays } from 'date-fns'
import "./MessageBubble.css"
import { Badge } from '@patternfly/react-core';
//format(new Date(), "'Today is' eeee")
// Today is Friday
//formatDistance(subDays(new Date(), 3), new Date(), { addSuffix: true })
// 3 days ago
//formatRelative(subDays(new Date(), 3), new Date())
// last Tuesday at 7:26 p.m.
/* Adapted From : https://www.freecodecamp.org/news/design-imessage-like-chat-bubble-react-native/ */

interface IMessageBubble {
  myProfileId: string,
  profile_name?: string,
  receiver_profile_name?: string,
  message: IMessage,
  showSeen: boolean,
  showSender: boolean
}
const MessageBubble = ({ myProfileId, profile_name = "", receiver_profile_name, message, showSeen, showSender }: IMessageBubble) => {
  const [showAllMetadata, setShowAllMetadata] = React.useState(false);

  const dateDistance = (date: Date) => formatDistance(date, new Date(), { addSuffix: true }).replace("about ", "").replace(" ago", "")
  const exactTime = (date: Date) => formatISO9075(date).substring(0, 16)


  const m = message
  const sentByMe = myProfileId == m.senderProfileId
  //console.log({m, sentByMe, myProfileId})

  const escapeAndFormatHtml = (plainText) => {
    //.replace("\n", "<br/>")  <-  no need if CSS  white-space: break-spaces
    //return escHtml(plainText);
    return plainText;
  }

  return (
    <div
      className={"MessageBubble" + (sentByMe ? " sentByMe" : "")}
      onClick={() => setShowAllMetadata(!showAllMetadata)}
    >

      {/* Message */}
      <div className={"Bubble" + (sentByMe ? " sent" : " received")} key={m.id}>
        <p>{escapeAndFormatHtml(m.bodyText)}</p>
        <div className={"arrow " + (sentByMe ? "rightArrow" : "leftArrow")} ></div>
        <div className={"arrowOverlap " + (sentByMe ? "rightArrowOverlap" : "leftArrowOverlap")} ></div>
      </div>

      {/* Metadata */}
      <div className='metadata'>
        {
          showAllMetadata
            ? (
              <span>
                {"Sent: " + exactTime(m.tsCreated) + ", Seen: " + (m.tsRecipientRead ? exactTime(m.tsRecipientRead) : "unseen")}
              </span>
            )
            : (
              <span>
                <span>{dateDistance(m.tsCreated)}</span>
                {/* <Badge className='seen' isRead>seen</Badge>&nbsp; */}
                {
                  (sentByMe && showSeen && m.tsRecipientRead != null)
                  ? ", seen by "                    
                  : <> </>
                }
              </span>
            )
        }
        {
          showSender
            ? (
              <span className='sender'>
                {/* {sentByMe ? <span>to </span> : <></>} */}
                <span className='profilename'>{profile_name} </span>
              </span>
            )
            : <></>
        }
      </div>

    </div>
  )
}

export { MessageBubble }