import { Alert, ListVariant, LoginForm, LoginMainFooterBandItem, Text } from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApiClient } from '../Util/ApiClient';
import { AppStateContext } from '../Util/AppState';
import { LoginPage } from './LoginPage';
import { AxiosResponse } from 'axios';

export const AppLogin: React.FunctionComponent = (props) => {
  const [apiLoginResult, setApiLoginResult] = React.useState("")
  const history = useHistory();
  const [showHelperText, setShowHelperText] = React.useState(false)
  const [username, setUsername] = React.useState('')
  const [isValidUsername, setIsValidUsername] = React.useState(true)
  const [password, setPassword] = React.useState('')
  const [isValidPassword, setIsValidPassword] = React.useState(true)
  const [isRememberMeChecked, setIsRememberMeChecked] = React.useState(false)
  const [loginButtonDisabled, setLoginButtonDisabled] = React.useState(false)
  const apiClient = useApiClient()
  const appState = useContext(AppStateContext)

  React.useEffect(()=>{
    //const afterLoginUrlPath = window.location.origin;
    const afterLoginUrlPath = "/";
    //appState.refresh()
    if (appState.auth.isLoggedIn) {
      console.log("history.push to " + afterLoginUrlPath + " auth:" + appState.auth.isLoggedIn)
      history.push(afterLoginUrlPath);
    } else {
      console.log(appState)
    }
  }, [apiLoginResult])

  const handleUsernameChange = (_event: React.FormEvent<HTMLInputElement>, value: string) => {
    setUsername(value);
  };

  const handlePasswordChange = (_event: React.FormEvent<HTMLInputElement>, value: string) => {
    setPassword(value);
  };

  const onRememberMeClick = () => {
    setIsRememberMeChecked(!isRememberMeChecked);
  };

  const onLoginButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setIsValidUsername(!!username);
    setIsValidPassword(!!password);
    setShowHelperText(!username || !password);
    login();
  }

  const [tokenVal, setTokenVal] = React.useState(new URLSearchParams(useLocation().search).get('token'));
  
  React.useEffect(() => {
    if (tokenVal) setTimeout(() => {
      let plain = atob(tokenVal)
      let parts = plain.split(":")    
      setUsername(parts[0])
      setPassword(parts[1])
      login()
    }, 500)
  })

  const getMessageByStatusCode = (response: AxiosResponse) => {
    switch (response.status) {
      case 401:
        return "Password and/or Email address is incorrect"
        break;
      default:
        return response.status + " "  + response.statusText
    }
  }


  const login = () => {
    let formData = new FormData();
    setLoginButtonDisabled(true)
    apiClient.authPost(username, password).then(res => {
      //console.log(res.data)
      //console.log("This should only happen once")

      setApiLoginResult(getMessageByStatusCode(res))
      
      //For Failed Login
      setIsValidUsername(!!username && username.includes("@"));
      setIsValidPassword(!!password);
      setShowHelperText(true);  
      setLoginButtonDisabled(false)
    }).catch((e) => {
      //console.log(e)
      //Failed Login is rejected when 302 by API calling fetch with redirect:"error""
      setIsValidUsername(!!username && username.includes("@"));
      setIsValidPassword(!!password);
      setApiLoginResult(getMessageByStatusCode(e.response))
      setShowHelperText(true);
      setLoginButtonDisabled(false)
      //console.error(e);
   }) 
  }
  
  const signUpForAccountMessage = (
    <LoginMainFooterBandItem>
      <a href="/register">Need to Register?</a>
    </LoginMainFooterBandItem>
  );

  const forgotCredentials = (
    <LoginMainFooterBandItem>
      <a href="/register">Need a login link?</a>
    </LoginMainFooterBandItem>
  );

  const listItems = (
    <></>
    // <React.Fragment>
    //   <ListItem>
    //     <LoginFooterItem href="#">Terms of Use </LoginFooterItem>
    //   </ListItem>
    //   <ListItem>
    //     <LoginFooterItem href="#">Help</LoginFooterItem>
    //   </ListItem>
    //   <ListItem>
    //     <LoginFooterItem href="#">Privacy Policy</LoginFooterItem>
    //   </ListItem>
    // </React.Fragment>
  )

  const loginForm = (
    <LoginForm
      showHelperText={showHelperText}
      helperText={apiLoginResult}
      helperTextIcon={<ExclamationCircleIcon />}
      usernameLabel="Email"
      usernameValue={username}
      onChangeUsername={handleUsernameChange}
      isValidUsername={isValidUsername}
      passwordLabel="Password"
      passwordValue={password}
      isShowPasswordEnabled
      onChangePassword={handlePasswordChange}
      isValidPassword={isValidPassword}
      rememberMeLabel=""  //disables Checkbox
      isRememberMeChecked={isRememberMeChecked}
      onChangeRememberMe={onRememberMeClick}
      onLoginButtonClick={onLoginButtonClick}
      loginButtonLabel="Log in"
      isLoginButtonDisabled={loginButtonDisabled}      
    />
  );

  const SiteMessage = ()=>{
    const devMessage = "This site is under active development, open registration is not available at this time."
    return <Alert variant={'danger'} title={devMessage}/>
  }

  return (
    <LoginPage
      footerListVariants={ListVariant.inline}
      //brandImgSrc={logo}
      //brandImgAlt="Gaydr.net"
      //backgroundImgSrc="/assets/images/pfbg-icon.svg"
      footerListItems={listItems}
      textContent="" //"Faster, simpler, easier, free!"
      loginTitle={
        <div style={{lineHeight: '1em'}}>
          <div style={{float: "right", fontSize:"80px", verticalAlign:"bottom", lineHeight:"57px"}}>&lambda;</div>
          Gaydar isn't real,<br/>
          <span style={{fontSize: "70%"}}>but gaydr.net can help</span>
          <br/>
          <br/>
          <SiteMessage/>
        </div>
      }
      //loginSubtitle=""
      //socialMediaLoginContent={socialMediaLoginContent}
      //socialMediaLoginAriaLabel="Log in with social media"
      signUpForAccountMessage={signUpForAccountMessage}
      forgotCredentials={forgotCredentials}
    >
      {loginForm}
    </LoginPage>
  );
  
};
