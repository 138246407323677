import * as React from 'react';
import { PageSection, Title } from '@patternfly/react-core';
import { ContactUsForm } from './ContactUsForm';

const ContactUs: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">Contact Us</Title>
    <ContactUsForm></ContactUsForm>
  </PageSection>
)

export { ContactUs };
