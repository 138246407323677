import * as React from 'react';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import {
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateFooter,
  PageSection,
} from '@patternfly/react-core';
import { useHistory } from 'react-router-dom';

const NotFound: React.FunctionComponent = () => {
  function GoHomeBtn() {
    const history = useHistory();
    function handleClick() {
      history.push('/login');
    }
    return (
      <Button onClick={handleClick}>Go to Login</Button>
    );
  }

  return (
    <PageSection>
      <EmptyState titleText="Oops - Are you supposed to be here?" variant="full" icon={ExclamationTriangleIcon} >
        <EmptyStateBody>
          You probaly need to login to access this page.
        </EmptyStateBody><EmptyStateFooter>
        <GoHomeBtn />
      </EmptyStateFooter></EmptyState>
    </PageSection>
  )
};

export { NotFound };
