import * as React from 'react';
import '@patternfly/react-core/dist/styles/base.css';
import '@app/App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppLayout } from './AppLayout/AppLayout';
import { AppRoutes } from './routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppStateContextComponent } from './Util/AppState';
import { useLocationReporting } from './Util/LocationReporting';
import { LocationReportingContext } from './Util/LocationReportingContext';
import CacheBuster from 'react-cache-buster';
import appinfo from './appinfo.json';
import { Spinner } from '@patternfly/react-core';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
});

const App: React.FunctionComponent = () => {  
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    // <CacheBuster
    //   currentVersion={appinfo.version}
    //   isEnabled={isProduction} //If false, the library is disabled.
    //   isVerboseMode={false} //If true, the library writes verbose logs to console.
    //   loadingComponent={<Spinner />} //If not pass, nothing appears at the time of new version check.
    //   metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    // >
      <Router>
        <AppInner />
      </Router>    
    // </CacheBuster>
  )
}

const LocationReportingContextComponent = ({children}) => {
  const locationReporting = useLocationReporting();  
  return (
    <LocationReportingContext.Provider value={locationReporting}>
      {children}
    </LocationReportingContext.Provider>
  )
}
const AppInner: React.FunctionComponent = () => {  
  
  //console.log("App Rendering");
  return (
    <QueryClientProvider client={queryClient}>
      <AppStateContextComponent>
        <LocationReportingContextComponent>        
          <AppLayout>
            <AppRoutes/>            
          </AppLayout>
        </LocationReportingContextComponent>        
      </AppStateContextComponent>
      {/* <ReactQueryDevtools initialIsOpen position='bottom' /> */}
    </QueryClientProvider>    
  )
};

export default App;