import React, { CSSProperties, ReactElement, useContext, useEffect, useRef, useState } from "react";
import "./ProfilePicRateSwipePage.css"
import { iterateArrayFrom, profilePicSteppedWidthApiUrl } from "../Util/AppUtil";
import { IProfile, IProfilePicLike, Like } from "../Util/AppData";
import { Button, PageSection, Text, TextContent, TextVariants } from "@patternfly/react-core";
import { useApiClient } from "../Util/ApiClient";
import { skipToken, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { ThumbsDownIcon, ThumbsUpIcon } from "@patternfly/react-icons";
// import TinderCard from "../../app/lib/react-tinder-card";
import TinderCard from "react-tinder-card"
import { useWindowSize } from "@uidotdev/usehooks";
import { now } from "moment";
import { AppStateContext } from "../Util/AppState";

const profilePicRateSwipePageUrl = (profileId: string, startProfilePicId: string) => "/profilePicRateSwipe?profileId=" + profileId + "&startProfilePicId=" + startProfilePicId;

const ProfilePicRateSwipePage = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const profileId = queryParams.get("profileId") || ""
  const startProfilePicId = queryParams.get("startProfilePicId")

  const appStateContext = useContext(AppStateContext)
  const sessionProfileId = appStateContext.sessionProfileId
  const windowSize = useWindowSize()
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const { status, data: profile, error, isLoading } = useQuery<IProfile, Error>({
    queryKey: ["profileGetById", profileId],
    queryFn: () => apiClient.profileGetById("" + profileId),
    refetchOnWindowFocus: true,
    staleTime: 3600000, //60min
    enabled: (profileId != null && startProfilePicId != null)
  });

  let profilePicLikesKey = ["profilePicLikesGet",sessionProfileId]
  const { data: profilePicLikes } = useQuery({
    queryKey: profilePicLikesKey,
    queryFn: sessionProfileId ? () => apiClient.profilePicLikesByLikedGet(sessionProfileId) : skipToken, 
    staleTime: 60 * 60 * 1000  // 1 hour in ms
  })

  const onLikeRating = (profilePicId: string, like: Like) => {
    let cache : IProfilePicLike[] = queryClient.getQueryData(profilePicLikesKey) as any as IProfilePicLike[]
    const profilePicLike : IProfilePicLike = {
      likerProfileId: sessionProfileId + "", 
      profilePicProfileId: profileId + "", //Incomplete no consequence
      profilePicId: profilePicId + "",
      likeType: like,
      tsCreated: new Date()
    }
    queryClient.setQueryData(profilePicLikesKey, (cache : IProfilePicLike[]) => {
      const r = cache.filter(i => i.profilePicId != profilePicId)
      r.push(profilePicLike)      
      return r;
    })
    apiClient.profilePicLikePut(profilePicLike)
  }

  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      {
        isLoading || profile == undefined || profilePicLikes == undefined
          ? <div>loading</div>
          : <ProfilePicRateSwipe profile={profile} startProfilePicId={"" + startProfilePicId} profilePicLikes={profilePicLikes} onLikeRating={onLikeRating} width={windowSize.width ? windowSize.width : 0} />
      }
    </PageSection>
  )
}

const ProfilePicRateSwipe = ({ profile, profilePicLikes, startProfilePicId, onLikeRating, width } : { profile: IProfile, profilePicLikes: IProfilePicLike[], startProfilePicId: string, onLikeRating: (profilePicId: string, like: Like) => void, width: number }) => {

  const profilePicIds = iterateArrayFrom(startProfilePicId, profile.profilePicIds);
  console.log(profile.profilePicIds)
  console.log(profilePicIds)

  const cards: ICard[] = profilePicIds.map((profilePicId): ICard => ({
    profile: profile,
    profilePicId: profilePicId,
    imageurl: profilePicSteppedWidthApiUrl(profilePicId, width),    
  }))

  return (
    <AppTinderCardsSet profile={profile} profilePicLikes={profilePicLikes} cards={cards} onLikeRating={onLikeRating} />
    // <AppCarousel />
  )
}

interface ICard {
  profile: IProfile
  profilePicId: string
  imageurl: string
}

const AppTinderCardsSet = ({ profile, profilePicLikes, cards, onLikeRating }:  { profile: IProfile, profilePicLikes: IProfilePicLike[], cards: ICard[], onLikeRating: (profilePicId: string, like: Like) => void }) => {
  const history = useHistory()

  //let a = [1, 2, 3, 4]
  //let reverseArray = [...a].reverse()
  //console.log(a, reverseArray) // [1,2,3,4], [4,3,2,1]
  //console.log(cards)
  const revCards = cards.slice().reverse()
  console.log(revCards)

  return (
    <div className="RateSwipe" style={{
      position:"absolute", top:0, bottom:0, left:0, right:0, margin:"0 0",
      display: "flex", justifyContent: "center"
    }}>
      {revCards.map((card, index) =>
        <AppTinderCard key={card.profilePicId} card={card} index={index} onLikeRating={onLikeRating} profile={profile} profilePicLikes={profilePicLikes}/>
      )}
    </div>
  )
}

//https://github.com/3DJakob/react-tinder-card/issues/74#issuecomment-1162533452
type Direction = 'left' | 'right' | 'up' | 'down'
export interface API {
  swipe(dir?: Direction): Promise<void>
  restoreCard(): Promise<void>
}

const AppTinderCard = ({ profile, profilePicLikes, card, index, onLikeRating }: { profile: IProfile, profilePicLikes: IProfilePicLike[], card: ICard, index: number, onLikeRating: Function }) => {
  const apiClient = useApiClient();
  
  const ref = React.createRef<API>()
  const history = useHistory()

  const appStateContext = useContext(AppStateContext)
  let myProfileId = appStateContext.settings?.defaultProfileId || ""
  
  const likeType = (() => {
    //console.log(profilePicLikes)
    let ppl = profilePicLikes.filter(l => l.profilePicId == card.profilePicId && l.likerProfileId == myProfileId)[0]
    if (!ppl) return Like.UNDETERMINED
    return ppl.likeType
  })()

  const onSwipe = (direction, card, index, ref) => {
    var likeType = direction == "right" ? Like.LIKE : direction == "left" ? Like.DISLIKE : direction == "down" ? Like.UNDETERMINED : Like.ERROR
    //console.log(`Pic ID ${card.profilePicId}, ${likeType}`);
    onLikeRating(card.profilePicId, likeType)
    if (index == 0) {
      history.goBack();
    }
    //console.log(ref.current)
  }
  
  let filtered = profilePicLikes.filter(i => i.profilePicId == card.profilePicId)
  
  return <>
    <TinderCard
      ref={ref}
      // key={card.profilePicId}
      className="AppTinderCard"
      preventSwipe={[`up`]} 
      onSwipe={(dir) => onSwipe(dir, card, index, ref)}        
    >
      {/* ...profilenamestyle */}
      <div className="card" style={{ backgroundImage: "url(" + card.imageurl + ")"     /*  IMAGE URL  */   }}>
        <span style={{display: "inline"}}>
          <span className="profilename" onClick={()=> {history.push("/profile?id=" + profile.id)}}>
            {profile.profilename}
          </span>
          { likeType == Like.LIKE &&<>&nbsp;&nbsp;&nbsp;<ThumbsUpIcon className="icon" style={{backgroundColor: "#444"}} color="red" /></> }
          { likeType == Like.DISLIKE &&<>&nbsp;&nbsp;&nbsp;<ThumbsDownIcon className="icon" style={{backgroundColor: "#aaa"}} color="blue" /></> }          
        </span>

        <div style={{ position: "absolute", bottom: "0px", margin: "20px", right: 0, left: 0, textAlign: "center" }} className='buttons'>
          <Button className="pressable" style={{position: "absolute", left: "0px", backgroundColor: '#ED2939', color: "white", fontWeight: "bolder" }} onClick={() => { if (ref.current) ref.current.swipe("left") }}>Meh</Button>
          {/* //Implement Undo!   https://www.npmjs.com/package/react-tinder-card */}
          <Button className="pressable" style={{left: "0px", backgroundColor: 'gray', color: "white", fontWeight: "bolder" }} onClick={() => { if (ref.current) ref.current.swipe("down") }}>Skip</Button>
          <Button className="pressable" style={{ position: "absolute", right: "0px", backgroundColor: '#007FFF', color: "white", fontWeight: "bolder" }} onClick={() => { if (ref.current) ref.current.swipe("right") }}>Nice</Button>
        </div>
      </div>
    </TinderCard>
  </>
}

export { ProfilePicRateSwipePage, profilePicRateSwipePageUrl }




