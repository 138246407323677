import React from "react";
import { useQueries } from "@tanstack/react-query";
import { LatLng, LatLngBounds } from "leaflet";
import { useApiClient } from "../../Util/ApiClient";
import { IProfileBrief } from "../../Util/AppData";
import { MapMarkerProfilesScaled } from "./MapMarkerProfilesScaled";
import { useMapMarkerProfilesWithMyMarker as addMyProfileBrief } from "./MapMarkerProfilesWithMyMarker";
import { ProfileLocationControlType } from "./MapPage";
import { MapMarkerProfilePositionAggregates } from './MapMarkerProfilePositionAggregates';
import { Rectangle, SVGOverlay } from "react-leaflet";
import { useProfileBriefsByPosition } from "@app/Util/AppDataPositionQueries";
import { AppSpinner } from "@app/Util/AppUtil";

const REFETCH_INTERVAL_SECS = 150
const MAP_MARKER_PROFILES_TOO_MANY = 800

interface IMapMarkerProfilesActiveComponent {
  bounds: LatLngBounds
  setDisappearingMessage: (msg: string) => void
  profileLocationControlType: ProfileLocationControlType
}
const MapMarkersBySectorParallel = ({ bounds, profileLocationControlType }: IMapMarkerProfilesActiveComponent) => {
  
  let { profileBriefs, numSectorsExceeded, pending } = useProfileBriefsByPosition({bounds, tooManySectors: 20, refetchIntervalMs: REFETCH_INTERVAL_SECS * 1000})

  let bTooManyForMap = false
  let bShowWorldMarkers = false
  if (numSectorsExceeded) bShowWorldMarkers = true
  if (profileBriefs.length >= MAP_MARKER_PROFILES_TOO_MANY) {
    bShowWorldMarkers = true;
    bTooManyForMap = true
    profileBriefs = []
  }

  //Always add my profile
  var profileBreifMap = addMyProfileBrief({ profileBriefsPropsArr: profileBriefs })

  return (
    <>
      {pending && <AppSpinner text="Loading Map Marker Profiles..."/>}
      { //Always show this for myMarker to be on the map
        <MapMarkerProfilesScaled
          profileBreifsMap={profileBreifMap}
          bounds={bounds}
          profileLocationControlType={profileLocationControlType}
        />
      }
      {
        (bShowWorldMarkers || bTooManyForMap) &&
        <div key="ZoomMessage">
          <Rectangle bounds={bounds} pathOptions={{ stroke: false, color: "#333", opacity: 0.5 }}>
          </Rectangle>
          <SVGOverlay attributes={{ stroke: 'lightgray' }} bounds={bounds} zIndex={999}>
            {
              bTooManyForMap &&
              <text x="50%" y="40%" textAnchor="middle" stroke="white" fill="white" style={{ padding: '1em', borderRadius: "1em", border: "10px solid white", fontSize: "24pt", fontWeight: "400", fontFamily: "Helvetica, Arial, sans-serif" }}>
              Too many profiles to display.
              </text>            
            }
            <text x="50%" y="45%" textAnchor="middle" stroke="white" fill="white" style={{ padding: '1em', borderRadius: "1em", border: "10px solid white", fontSize: "24pt", fontWeight: "400", fontFamily: "Helvetica, Arial, sans-serif" }}>
              Zoom in to see profiles.
            </text>
          </SVGOverlay>
        </div>
      }
      {bShowWorldMarkers && <MapMarkerProfilePositionAggregates bounds={bounds} />}
    </>
  )
}

export { MapMarkersBySectorParallel };