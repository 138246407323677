import * as React from 'react';
import './ChatConversation.css';
import { useApiClient } from '@app/Util/ApiClient';
import { Flex } from '@patternfly/react-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
//import { WsChat } from './WsChat';
//import { LexEditor } from '@app/Util/LexEditor/LexEditor';
//import { Editor as SujiEditor } from '@app/Util/LexEditSuji/src/Editor';
import { MessageEditor } from './ChatEditor';
import { IMessage, IProfile } from '@app/Util/AppData';
import { MessageBubble } from './MessageBubble';
import { AppStateContext } from '@app/Util/AppState';
import { AppSpinner } from '@app/Util/AppUtil';

//format(new Date(), "'Today is' eeee")
// Today is Friday
//formatDistance(subDays(new Date(), 3), new Date(), { addSuffix: true })
// 3 days ago
//formatRelative(subDays(new Date(), 3), new Date())
// last Tuesday at 7:26 p.m.

interface IChatConversation {
  myProfileId: string
  profile: IProfile  
  focused?: boolean
}
const ChatConversation = ({ myProfileId, profile, focused = false }: IChatConversation) => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const ref = React.useRef<HTMLDivElement>(null)
  const QK_API_MESSAGES_WITH = '/api/messages/with/';
  const invalidateChatHistory = () => queryClient.invalidateQueries({ queryKey: [QK_API_MESSAGES_WITH, profile.id] })

  //TODO Implement paging
  const { status, data : messages, error, isLoading } = useQuery<IMessage[], Error>({
    queryKey: [QK_API_MESSAGES_WITH, profile.id],
    queryFn: () => apiClient.messagesWithGet(myProfileId, profile.id, "0"),
  });

  React.useEffect(() => { 
    //console.log("effect")
    focused && ref.current && (
      //console.log("effecting"), 
      (ref.current as unknown as Element).scrollIntoView() 
    )
  })
  //console.log(data?.messages)

  return (
    <Flex className='ChatConversation' direction={{ default: 'column' }}>

      { /* Chat Editor */}
      <div ref={ref}></div>
      <MessageEditor senderProfileId={myProfileId} recipientProfileId={profile.id} draftMessage={""} onMessageSent={invalidateChatHistory} focused={focused && messages !== undefined && messages.length !== undefined && messages.length > 0} />
      {
        isLoading
          ? <AppSpinner />
          :
            (!messages || (messages && messages.length < 1))
            ? <></>
            // <FlexItem key="none">None</FlexItem>
            : <Flex className="ChatMessageList" direction={{ default: 'column' }}>
              {/* <ChatConversationMessages myProfileId={data.myProfileId} profile={profile} messages={data.messages} /> */}
              <div className="ChatConversationMessages" >
                {
                  messages.map((m, i) => {
                    const cm = <MessageBubble key={m.id} myProfileId={myProfileId} profile_name={profile.profilename} message={m} showSeen={true} showSender={false} />
                    return cm
                  })
                }
              </div>
            </Flex>
      }
    </Flex>
  )
}

export { ChatConversation };
