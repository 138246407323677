import * as React from 'react';
import { CubesIcon } from '@patternfly/react-icons';
import {
  Button,
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateVariant,
  PageSection,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core';
import { AppContextInfo } from './AppContextInfo'

let DonatePage = () => (
  <PageSection>
      <Text component={TextVariants.h2}>Donations</Text>
      <Text component={TextVariants.h3}>This site is funded by private donations by people like you.</Text>
      <br/>
      <Text component='p'>
        Our mission is to run lean and free for our users.  This still requires minimal adminstrative and hosting expenses.
      </Text>
      <Text component='p'>        
        Please contribute by Zelle, Credit Card, or even Bitcoin.
      </Text>         
  </PageSection>
);

export { DonatePage };
