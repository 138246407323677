import * as React from 'react';
import { Alert, Button, Checkbox, Flex, FlexItem, Grid, GridItem, Split, SplitItem, TextInput } from "@patternfly/react-core"
import { IPushNotificationSubscription } from '../Util/AppData';
import { usePWAPushNotification } from './PWA';

//https://dev.to/ajayupreti/how-to-use-push-notifications-in-react-a-step-by-step-guide-341d
interface IAppPushNotification {
  onPushNotificationSubscription(deviceName: string, pushSubscription: PushSubscription): void
  applicationServerKey: ArrayBuffer
}
const AppPushNotification = ({ onPushNotificationSubscription, applicationServerKey }: IAppPushNotification) => {
  const [usePushNotifications, setUsePushNotifications] = React.useState(false)
  const [error, setError] = React.useState("")
  const [deviceName, setDeviceName] = React.useState("" + ((Math.random() * 1000) % 100) + ":" + self.navigator.userAgent.substring(0, 15))
  const [subscription, setSubscription] = React.useState<PushSubscription>()

  const pwaPushNotification = usePWAPushNotification({
    subscribeForPushNotification: usePushNotifications,
    applicationServerKey: applicationServerKey,
    onFoundPushSubscription: (subscription: PushSubscription) => {
      setSubscription(subscription)
      onPushNotificationSubscription(deviceName, subscription)
    }
  })

  return (
    <div>
      {
        pwaPushNotification.status
          ? <Alert title={pwaPushNotification.status.toString()} variant='info' />
          : <></>
      }
      {
        pwaPushNotification.error
          ? <Alert title={pwaPushNotification.error.toString()} variant='warning' />
          : <></>
      }
      {
        !subscription
          ? <Split hasGutter>
            <SplitItem>
              <Button onClick={() => { console.log("clicked"); setUsePushNotifications(true) }} >Subscribe</Button>
            </SplitItem>
            <SplitItem isFilled>
              <TextInput id="deviceName" value={deviceName} onChange={(e, v) => setDeviceName(v)} />
            </SplitItem>
          </Split>
          : <></>
      }

    </div>

  )


}

export { AppPushNotification as AppPushNotifications }