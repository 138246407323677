import * as React from 'react';
import { PageSection, Title } from '@patternfly/react-core';

const SorryPage: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">Ooops!</Title>
    <p>Well, this is embarassing.</p>
    <p>We're sorry, we are experiencing technical difficulties at this time.</p>
    <p>Please try again later.</p>    
  </PageSection>
)

export { SorryPage };
