import React from 'react'
import { useApiClient } from '../Util/ApiClient'
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query'
import { IProfileLike } from '../Util/AppData';
import { Button, Grid, GridItem, PageSection, Text, TextContent } from '@patternfly/react-core';
import { AppSpinner } from '../Util/AppUtil';
import { ProfileCardLazy } from '../Profile/ProfileCard';
import { useHistory } from 'react-router-dom';
import { LikesJumpLinks } from './LikesPageLib';
import { AppStateContext } from '@app/Util/AppState';

const ProfilesYouLiked = () => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const [saveMessage, setSaveMessage] = React.useState("");
  const history = useHistory()

  const appState = React.useContext(AppStateContext)
  const sessionProfileId = appState.sessionProfileId
  
  const QUERY_KEY = ["profileLikesByLikerGet"]

  const { status, data: likes, error, isFetching } = useQuery<IProfileLike[], Error>({
    queryKey: QUERY_KEY,
    queryFn: sessionProfileId ? () => apiClient.profileLikesByLikerGet(sessionProfileId) : skipToken
  });

  if (!likes) return <AppSpinner />
  return (
    <>
      {/* {JSON.stringify(likes)} */}
      <TextContent><Text component="h2" style={{textAlign:"center", paddingBottom: "1em"}}>Profiles You Liked</Text></TextContent>
      {likes.length == 0 && <div style={{textAlign:"center"}}>Have no suitors have caught your eye?</div>} 
      <Grid 
        sm={12} md={12} lg={6} xl={4} xl2={3}
        hasGutter
      >
        {likes.map((item,pos) => {
          return (
            <GridItem key={item.likedProfileId}>
              <ProfileCardLazy profileId={item.likedProfileId} showAction={false} showDescription={false} >
                <Button onClick={() => history.push("/profile?id=" + item.likedProfileId)} >
                  Profile
                </Button>
              </ProfileCardLazy>              
            </GridItem>
          )
          
        })}
      </Grid>
    </>
  )
}

const ProfilesYouLikedPage = (props) => {  
  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      {/* <Title style={{textAlign:"center"}} headingLevel="h1" size="lg">Likes</Title> */}
      <LikesJumpLinks />
      <ProfilesYouLiked />
    </PageSection>
  )
}

export { ProfilesYouLikedPage }
