import * as React from 'react';
import './BroadcastMessagesPage.css'

import { useApiClient } from '../Util/ApiClient';
import { IMessage } from '../Util/AppData';
import { Divider, NavItemSeparator, PageSection, Stack, StackItem, Text, TextContent, Title } from '@patternfly/react-core';
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query';
import { BroadcastMessageItem } from './BroadcastMessageItem';
import { AppStateContext } from '@app/Util/AppState';
import { AppSpinner } from '@app/Util/AppUtil';
import { MessageEditor } from './ChatEditor';
import { useMessagesByPosition } from '@app/Util/AppDataPositionQueries';
import { useLocationReporting } from '@app/Util/LocationReporting';

interface ICardList { }

const BroadcastMessagesPage: React.FunctionComponent<ICardList> = (props) => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const [saveMessage, setSaveMessage] = React.useState("");
  const appState = React.useContext(AppStateContext)

  const sessionProfileId = appState.sessionProfileId
  const myPos = useLocationReporting().reportingLatLng

  const { messages, pending } = useMessagesByPosition({position: myPos, sectorRadius: 1.0, goingBackMs: 60 * 60 * 1_000, refetchIntervalMs: 60000})

  return (
    <PageSection className="BroadcastMessagesPage" height="100%" width="100%" style={{position:"relative",margin:0, padding:"1em"}}>
      {
      /* <Title headingLevel="h1" size="lg">
        Conversations
      </Title>      
      <Divider /> */
      }
      <Text component='h2'>Broadcast Messages</Text>
      <Divider />
      {
        sessionProfileId == null || pending || messages === undefined
          ? <AppSpinner />
          :
          <Stack className='Conversations'>
            {
              !messages
              ? <StackItem style={{color: "white"}} key="NoMessages">No messages found in your area.</StackItem>
              : messages.map(m => {
                  //console.log(profile);
                  //console.log(message);                    
                  return (
                    <StackItem className="Item" key={m.id}>
                      <BroadcastMessageItem message={m} />
                      {/* <Divider inset={{default:"insetNone"}}/> */}
                    </StackItem>
                  )
                })
            }
            <StackItem key="editor">
              <MessageEditor draftMessage='' focused={true} onMessageSent={() => {}} senderProfileId={sessionProfileId} recipientProfileId={undefined} />
            </StackItem>
          </Stack>
      }
    </PageSection>

  )
}

export { BroadcastMessagesPage };
