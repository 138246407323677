import React from 'react';
import appinfo from '../appinfo.json'
import { LocationReportingContext } from '../Util/LocationReportingContext';
import { Text, TextVariants } from '@patternfly/react-core';
import { GoogleMapsLink } from '../Util/AppUtil';
import { useApiClient } from '@app/Util/ApiClient';
import { useQuery } from '@tanstack/react-query';

const AppContextInfo = () => {
  const apiClient = useApiClient();
  const { data:version } = useQuery({queryKey: ["version"], queryFn: () => apiClient.version()})

  const locationReporting = React.useContext(LocationReportingContext)
  
  return (
    <Text component={TextVariants.small} style={{ fontSize: "10px" }}>
      <div>
        <Text component={TextVariants.h4}>Web Version:</Text> 
        <div>{appinfo?.version}</div>
        <div>{appinfo?.date}</div>
      </div>
      <br/>
      <div>
        <Text component={TextVariants.h4}>API Version:</Text> 
        <div>{version?.gitCommitHash}</div>
        <div>{version?.gitCommitTs}</div>
      </div>
      <br/>
      <div>
        <Text component={TextVariants.h4}>Device Location Reporting:</Text>       
        {
          locationReporting 
          &&
          <div>
            <GoogleMapsLink locationReporting={locationReporting} />
            <div>{locationReporting.toString()}</div>
          </div>                  
        }      
      </div>
    </Text>
  )
}

export { AppContextInfo }