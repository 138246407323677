import * as React from 'react';
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import { Dashboard } from './Dashboard/Dashboard';

/*
import { GeneralSettings } from '@app/Settings/General/GeneralSettings';
import { ProfileSettings } from '@app/Settings/Profile/ProfileSettings';
*/
import { HomePage } from './Home/HomePage';
import { NotFound } from './NotFound/NotFound';
import { useDocumentTitle } from './utils/useDocumentTitle';
import { AboutUs } from './AboutUs/AboutUs';
import { MapPage } from './Search/Map/MapPage';
import { SettingsPage as SettingsPage } from './Settings/General/SettingsPage';
import { ProfileEditPage } from './Profile/ProfileEditPage';
import { ContactUs } from './ContactUs/ContactUs';
import { AppLogin } from './Auth/AppLogin';
import { AppLogout } from './Auth/AppLogout';
import { ProfilePictureUpload } from './Profile/ProfilePictureUpload';
import { ProfilesPage } from './Search/ProfilesPage';
import { TilesPage } from './Search/Tiles/ProfileTilesPage';
import { RegisterPage } from './Auth/RegisterPage';
import { SorryPage } from './Sorry/SorryPage';
import { ConversationsListPage } from './Chat/ConversationsListPage';
import { BroadcastMessagesPage } from './Chat/BroadcastMessagesPage';
import { ProfilePage } from './Profile/ProfilePage';
import { ProfilePictureEditPage } from './Profile/ProfilePictureEditPage';
import { ProfilePicCarouselPage } from './Profile/ProfilePicCarouselPage';
import { ProfilePicRateSwipePage } from './Profile/ProfilePicRateSwipePage';
import { AppStateContext } from './Util/AppState';
import { GamesPage } from './Interactive/Games';
import { ProfilesLikedYouPage } from './Likes/ProfileLikedYou';
import { ProfilesLikedYourPicsPage } from './Likes/ProfileLikedYourPics';
import { ProfilesYouLikedPage } from './Likes/YouLikedProfile';
import { ProfilePicsYouLikedPage } from './Likes/YouLikedProfilePics';
import { SupportPage } from './Support/SupportPage';
import { DonatePage } from './Support/DonatePage';
import { NotificationsPage } from './Chat/NotificationsPage';

let routeFocusTimer: number;
export interface IAppRoute {
  label?: string; // Excluding the label will exclude the route from the nav sidebar in AppLayout
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  exact?: boolean;
  path: string;
  title: string;
  needsAuthenticated : boolean;
  routes?: undefined;
  showOnMenu?: boolean;
}

export interface IAppRouteGroup {
  label: string;
  routes: IAppRoute[];
  showOnMenu?: boolean;
  //this is a kludge
  path?: string;
  needsAuthenticated : boolean;
}

export type AppRouteConfig = IAppRoute | IAppRouteGroup;

let routes: AppRouteConfig[] = [

  // {
  //   component: Dashboard,
  //   exact: true,
  //   label: 'Dashboard',
  //   path: '/dashboard',
  //   title: 'gaydr | Dashboard',
  //   needsAuthenticated: true,
  // },
  {
    component: MapPage,
    exact: true,
    label: 'Map',
    path: '/search/map',
    title: 'gaydr | Map Search',
    needsAuthenticated: true,
  },
  {
    component: TilesPage,
    exact: true,
    label: 'Tiles',
    path: '/search/tile',
    title: 'gaydr | Tile Search',
    needsAuthenticated: true,
  },
  {
    component: ProfilesPage,
    exact: true,
    label: 'Profiles',
    path: '/search/profile',
    title: 'gaydr | Profile Search',
    needsAuthenticated: true,
  },
  {
    component: GamesPage,
    exact: true,
    label: 'Interactive',
    path: '/games',
    title: 'gaydr | Community and Games',
    needsAuthenticated: true,
  },
  {
    label: 'Chat',
    routes: [
      {
        component: ConversationsListPage,
        exact: true,
        label: 'Personal',
        path: '/chat/personal',
        title: 'gaydr | Personal Messages',
        needsAuthenticated: true,
      },
      {
        component: BroadcastMessagesPage,
        exact: true,
        label: 'Broadcast',
        path: '/chat/broadcast',
        title: 'gaydr | Broadcast Messages',
        needsAuthenticated: true,
      },
    ],
    needsAuthenticated: true,
  },
  {
    component: NotificationsPage,
    exact: true,
    label: 'Notifications',
    path: '/notifications',
    title: 'gaydr | Notifications',
    needsAuthenticated: true,
  },
  {
    label: 'Likes',
    routes: [
      {
        component: ProfilesLikedYouPage,
        exact: true,
        label: 'Liked You',
        path: '/likes/profilelikedyou',
        title: 'gaydr | likes',
        needsAuthenticated: true,
      },
      {
        component: ProfilesLikedYourPicsPage,
        exact: true,
        label: 'Liked Your Pics',
        path: '/likes/profilelikedyourpics',
        title: 'gaydr | likes',
        needsAuthenticated: true,
      },
      {
        component: ProfilesYouLikedPage,
        exact: true,
        label: 'You Liked',
        path: '/likes/youlikedprofile',
        title: 'gaydr | likes',
        needsAuthenticated: true,
      },
      {
        component: ProfilePicsYouLikedPage,
        exact: true,
        label: 'Liked Their Pics',
        path: '/likes/youlikedprofilepics',
        title: 'gaydr | likes',
        needsAuthenticated: true,
      },      
    ],    
    path: '/likedyou/profile',
    needsAuthenticated: true,
  },
  {
    component: ProfileEditPage,
    exact: true,
    label: 'Edit Profile',
    path: '/profileEdit',
    title: 'gaydr | Profile Editor',
    needsAuthenticated: true,
  },
  {
    component: ProfilePictureEditPage,
    exact: true,
    label: 'Edit Profile Pic',
    path: '/profilePicEdit',
    title: 'gaydr | Profile Pic Editor',
    needsAuthenticated: true,
    showOnMenu: false,
  },
  {
    component: ProfilePage,
    exact: true,
    label: 'Profile',
    path: '/profile',
    title: 'gaydr | Profile',
    showOnMenu: false,
    needsAuthenticated: true,
  },
  {
    component: ProfilePicCarouselPage,
    exact: false,
    label: 'Profile Picture Carousel',
    path: '/profilePicCarousel*',
    title: 'gaydr | Profile Picture Carousel',
    needsAuthenticated: true,
    showOnMenu: false
  },
  {
    component: ProfilePicRateSwipePage,
    exact: false,
    label: 'Profile Picture RateSwipe',
    path: '/profilePicRateSwipe*',
    title: 'gaydr | Profile Picture RateSwire',
    needsAuthenticated: true,
    showOnMenu: false
  },
  {
    component: ProfilePictureUpload,
    exact: true,
    label: 'Upload Profile Picture',
    path: '/profilePicUpload',
    title: 'gaydr | Profile Picture Upload',
    needsAuthenticated: true,
    showOnMenu: false
  },
  {
    component: SettingsPage,
    exact: true,
    label: 'Settings',
    path: '/settings',
    title: 'gaydr | Settings',
    needsAuthenticated: true,
  },
  {
    component: HomePage,
    exact: true,
    label: 'Home',
    path: '/',
    title: 'gaydr | Home',
    needsAuthenticated: false,
  },  
  {
    component: AboutUs,
    exact: true,
    label: 'About Us',
    path: '/aboutus',
    title: 'gaydr | About Us',
    needsAuthenticated: false,
  },
  {
    component: ContactUs,
    exact: true,
    label: 'Contact Us',
    path: '/contactus',
    title: 'gaydr | Contact Us',
    needsAuthenticated: true,
  },  
  {
    component: SupportPage,
    exact: true,
    label: 'Support',
    path: '/support',
    title: 'gaydr | Support',
    needsAuthenticated: false,
  },  
  {
    component: DonatePage,
    exact: true,
    label: 'Donate',
    path: '/donate',
    title: 'gaydr | Donate',
    needsAuthenticated: false,
  },
  {
    component: AppLogin,
    exact: true,
    label: 'Login / Register',
    path: '/login',
    title: 'gaydr | Login',
    needsAuthenticated: false,
  },
  {
    component: RegisterPage,
    exact: true,
    label: 'Register',
    path: '/register',
    title: 'gaydr | Register',
    needsAuthenticated: false,
    showOnMenu: false
  },
  {
    component: AppLogout,
    exact: true,
    label: 'Logout',
    path: '/logout',
    title: 'gaydr | Logout',
    needsAuthenticated: true,
  },
  {
    component: SorryPage,
    exact: true,
    label: 'Techincal Difficulties',
    path: '/sorry',
    title: 'gaydr | Technical Difficuties',
    needsAuthenticated: false,
    showOnMenu: false
  },  
];  

const navPathsUnauthenticatedOnly = ['/login', '/register'];
const navPathsShowNever = routes.filter((r)=> r.showOnMenu === false).map((r) => r.path);

// a custom hook for sending focus to the primary content container
// after a view has loaded so that subsequent press of tab key
// sends focus directly to relevant content
// may not be necessary if https://github.com/ReactTraining/react-router/issues/5210 is resolved
const useA11yRouteChange = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    routeFocusTimer = window.setTimeout(() => {
      const mainContainer = document.getElementById('primary-app-container');
      if (mainContainer) {
        mainContainer.focus();
      }
    }, 50);
    return () => {
      window.clearTimeout(routeFocusTimer);
    };
  }, [pathname]);
};

const RouteWithTitleUpdates = ({ component: Component, title, ...rest }: IAppRoute) => {
  useA11yRouteChange();
  useDocumentTitle(title);

  function routeWithTitle(routeProps: RouteComponentProps) {
    return <Component {...rest} {...routeProps} />;
  }

  return <Route render={routeWithTitle} {...rest} />;
};

const PageNotFound = ({ title }: { title: string }) => {
  useDocumentTitle(title);
  return <Route component={NotFound} />;
};

const flattenedRoutes: IAppRoute[] = routes.reduce(
  (flattened, route) => [...flattened, ...(route.routes ? route.routes : [route])],
  [] as IAppRoute[]
);

const AppRoutes = (props) => {
  const appState = React.useContext(AppStateContext)
  const canView = (r) => {
    const b = !r.needsAuthenticated || appState.auth.isLoggedIn
    //console.log(b)
    return b
  }
  let filteredRoutes = flattenedRoutes.filter((r) => canView(r));
  //console.log(filteredRoutes);
  return (
    <Switch>
      {filteredRoutes.map(({ path, exact, component, title, needsAuthenticated: needsAuth }, idx) => (
        <RouteWithTitleUpdates path={path} exact={exact} component={component} key={idx} title={title} needsAuthenticated={needsAuth}/>
      ))}
      <PageNotFound title="404 Page Not Found" />
    </Switch>
  );

}

export { AppRoutes, routes, navPathsUnauthenticatedOnly as navPathsOnlyUnauthenticated, navPathsShowNever };
