import * as React from 'react';
import { Button, JumpLinks, JumpLinksItem } from '@patternfly/react-core';
import { useHistory, useLocation } from 'react-router-dom';

interface PageLink { href: string, title: string }
const LikesJumpLinks = () => {
  const pageLinks: PageLink[] = [
    { href: "/likes/profilelikedyou", title: "Liked You" },
    { href: "/likes/profilelikedyourpics", title: "Your Pics" },
    { href: "/likes/youlikedprofile", title: "You Liked" },
    { href: "/likes/youlikedprofilepics", title: "Their Pics" },
  ]
  const location = useLocation()
  const history = useHistory()
  return (
    <>
      {/* <div style={{ textAlign: "center" }}>
        {
          pageLinks.map(l => <Button key={l.href} onClick={() => history.push(l.href)} disabled={location.pathname == l.href}>{l.title}</Button>)
        }
      </div> */}      
      <JumpLinks isCentered>
        {
          //Makes no sense but hrefi s required.   This error still works
          pageLinks.map(l => <JumpLinksItem key={l.href} href={"javascript:void(0);"} onClick={() => history.push(l.href)} isActive={location.pathname == l.href}>{l.title}</JumpLinksItem>)
        }
      </JumpLinks>
    </>
  )
}

export { LikesJumpLinks };
