import * as React from 'react'
import './ProfilePicThumbs.css'
import { Bullseye, Flex, FlexItem, Gallery, GalleryItem, Grid, GridItem, Slider } from "@patternfly/react-core";
import { Link } from 'react-router-dom';
import { profilePicSteppedWidthApiUrl } from '@app/Util/AppUtil';
import { profilePicCarouselPageUrl } from '@app/Profile/ProfilePicCarouselPage';
import { profilePicRateSwipePageUrl } from '@app/Profile/ProfilePicRateSwipePage';
import { IProfile } from '@app/Util/AppData';
import { useSize } from 'react-use';
import { useMeasure } from '@uidotdev/usehooks';


// Import css files
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

const PicThumbRaw = ({ src, width }: { src: string, width: number }) => {
  return (
    /* cover, contain, fill */
    <>
      <div style={{
        outline: "1px solid black",
        width: width,
        // width:"100%",
        maxWidth: width,
        // height: Math.floor(width * 6.0 / 5.0)
        //  height: "fit-content(120%)"
        aspectRatio: "5/6",
        backgroundImage: "url(" + src + ")",
        WebkitBackgroundSize: 'cover'
      }}>
      </div>
      {/* <img className="fit" style={{width: width, height: Math.floor(width * 6.0 / 5.0)}} src={src} loading='lazy' /> */}

    </>
  )
}

const ProfilePicThumb = ({ profilePicId, width }: { profilePicId: string, width: number }) => {
  let efficientSource = profilePicSteppedWidthApiUrl(profilePicId, width)
  return (
    /* cover, contain, fill */
    <PicThumbRaw src={efficientSource} width={width} />
  )
}

const PicThumbWithHref = ({ to, src, width }: { to: string, src: string, width: number }) => {
  return (
    /* cover, contain, fill */
    <Link className='ProfilePicThumb' to={{ pathname: to }}>
      <div style={{
        outline: "1px solid black",
        width: width,
        // width:"100%",
        maxWidth: width,
        // height: Math.floor(width * 6.0 / 5.0)
        //  height: "fit-content(120%)"
        aspectRatio: "5/6",
        backgroundImage: "url(" + src + ")",
        WebkitBackgroundSize: 'cover'
      }}>
      </div>
      {/* <img className="fit" style={{width: width, height: Math.floor(width * 6.0 / 5.0)}} src={src} loading='lazy' /> */}
    </Link>
    // </div>
  )
}

const ProfilePicThumbHref = ({ profile, profilePicId, width }: { profile: IProfile, profilePicId: string, width: number }) => {
  //const pageUrl = profilePicCarouselPageUrl
  //const pageUrl = profilePicCarouselPageUrl(profileId, profilePicId, profilePicIds)
  const pageUrl = profilePicRateSwipePageUrl(profile.id, profilePicId);

  return (
    /* cover, contain, fill */
    // <div  >
    // <Link className='ProfilePicThumb' to={{ pathname: pageUrl }}>
    //   <img className="fit" src={profilePicSteppedWidthApiUrl(profilePicId, width)} loading='lazy' />
    // </Link>
    // </div>
    <PicThumbWithHref to={pageUrl} src={profilePicSteppedWidthApiUrl(profilePicId, width)} width={width} />
  )
}

interface IProfileImgThumbs {
  profile: IProfile
  profilePicIds: string[]
  width: number
}
const ProfilePicThumbs = ({ profile, profilePicIds, width }: IProfileImgThumbs) => {
  if (!profilePicIds) return <></>
  return (
    <>
      {/* <ProfilePicThumbsGrid profile={profile} profilePicIds={profilePicIds} width={width} /> */}
      {/* <ProfilePicThumbsFlex profile={profile} profilePicIds={profilePicIds} width={width} /> */}
      <ProfilePicThumbsInlineblock profile={profile} profilePicIds={profilePicIds} width={width} />
    </>
  );
}


const ProfilePicThumbsGrid = ({ profile, profilePicIds, width }: { profile: IProfile, profilePicIds: string[], width: number }) => {

  if (!profilePicIds) return <></>
  return (
    // <Grid className='ProfilePicThumbs' hasGutter sm={3} md={3} lg={3} xl2={3}>
    //     {profilePicIds.map((id, x) => <GridItem><ProfilePicThumb key={id + "" + x} profilePicId={id} width={width} /></GridItem>)}
    // </Grid>

    <Grid
      className='ProfilePicThumbsGrid'
      hasGutter
      sm={3} md={3} lg={3} xl={3} xl2={3}
    >
      <style>
        {`
        /* @media screen and (min-width: 100px) */
        .ProfilePicThumbsGrid.pf-v6-l-grid.pf-m-all-3-col-on-sm > * {
          --pf-v6-l-grid__item--GridColumnEnd: span 3;
        }
        `}
      </style>
      {/* <div className='ProfilePicThumbs'>
              {profilePicIds.map((id, x) => <ProfilePicThumb key={id + "" + x} profileId={profileId} profilePicId={id} profilePicIds={profilePicIds} width={width} />)}
            </div> */}
      {
        profilePicIds.map((profilePicId, x) =>
          <GridItem key={profilePicId}>
            <ProfilePicThumbHref profile={profile} profilePicId={profilePicId} width={width} />
          </GridItem>
        )
      }
    </Grid>

  );
}

const ProfilePicThumbsFlex: React.FunctionComponent<IProfileImgThumbs> = ({ profile, profilePicIds, width }) => {
  if (!profilePicIds) return <></>
  return (
    <Flex
      // gap={{ default: 'gapMd' }} 
      justifyContent={{}}
      direction={{ default: 'row' }}
      gap={{ default: 'gapNone' }}
    >
      {
        profilePicIds.map((profilePicId, x) =>
          <FlexItem key={profilePicId} style={{ marginRight: "1em", marginBottom: "1em", marginTop: "1em" }}>
            <ProfilePicThumbHref key={profilePicId + "" + x} profile={profile} profilePicId={profilePicId} width={width} />
          </FlexItem>
        )
      }

    </Flex>

  );
}

const ProfilePicThumbsInlineblock: React.FunctionComponent<IProfileImgThumbs> = ({ profile, profilePicIds, width }) => {
  const [newWidth, setNewWidth] = React.useState(width)
  const divRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const updateWidth = () => {
      if (divRef.current) {
        const totalwidth = divRef.current.clientWidth;
        const marginPix = 16        
        const imageWidthForXColYMargin = (totalwidth: number, numCols: number, xMarginPix: number) => 
          ((totalwidth - ((numCols) * (xMarginPix + 1))) / numCols)
        let imgx = 0
        if (totalwidth < 200) imgx = imageWidthForXColYMargin(totalwidth, 2, marginPix)
        else if (totalwidth < 400) imgx = imageWidthForXColYMargin(totalwidth, 4, marginPix)
        else if (totalwidth < 600) imgx = imageWidthForXColYMargin(totalwidth, 6, marginPix)
        else imgx = imageWidthForXColYMargin(totalwidth, 8, marginPix)
        setNewWidth(imgx);
      }
    }
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  if (!profilePicIds) return <></>
  return (
    <div ref={divRef} className="ProfilePicThumbs InlineBlock" style={{ marginTop: "1em" }}>
      {
        profilePicIds.map((profilePicId, x) =>
          <div key={profilePicId} style={{ display: "inline-block", marginRight: "16px", marginBottom: "16px" }}>
            <ProfilePicThumbHref key={profilePicId + "" + x} profile={profile} profilePicId={profilePicId} width={newWidth} />
          </div>
        )
      }
    </div>
  );
}

export { ProfilePicThumbs, ProfilePicThumb, ProfilePicThumbHref, PicThumbRaw }