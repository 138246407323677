import React, { ReactElement } from "react"


const ComponentWheel = ({items}: {items:{el: ReactElement|string, timeoutMs: number}[]}) => {
  const [x, setX] = React.useState(0)
  React.useEffect(()=> {
    setTimeout(()=> {
      setX((x+1) % items.length)
    }, items[x].timeoutMs)
  }, [x])
  return <>{items[x].el}</>
}
export { ComponentWheel }