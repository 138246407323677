import * as React from 'react';
import { Button, Stack, StackItem } from '@patternfly/react-core';
import { html } from '@app/Util/AppUtil';

interface IExpandingPlaintextDiv {
  plaintext: string;
  classname?: string;
  maxHeight: string;
  showMoreButtonText?: String
}
export const ExpandingPlaintextDiv = ({ plaintext, classname, maxHeight, showMoreButtonText = "Show More" }: IExpandingPlaintextDiv) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  //const { ref, isOverflowY } = useOverflowY();
  const isOverflowY = useIsOverflow(ref, () => { })
  const [showAll, setShowAll] = React.useState(false);
  let style: React.CSSProperties = {};
  if (showAll) { }
  if (!showAll) { 
    style = { maxHeight: maxHeight, overflowY: "hidden" }; 
    //style = { overflowY: "hidden" }; 
  }
  return (
    <Stack className="ExpandingPlaintextDiv">
      <StackItem isFilled>
        <div ref={ref} className={classname} style={{ ...style, position: "relative" }}>
          {html.encodeP(plaintext ? plaintext : "")}      
        </div>
      </StackItem>
      
    {
      isOverflowY
        ? <StackItem style={{marginTop: "1em"}}>
            <Button onClick={() => { setShowAll(!showAll); }} style={{ fontWeight: "bold", width: "100%" }}>{showMoreButtonText}</Button>
          </StackItem>
        : <></>
    }
    </Stack>
  );
};

export const useIsOverflow = (ref, callback) => {
  const [isOverflow, setIsOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};