import * as React from 'react';
import { keepPreviousData, skipToken, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '@app/Util/ApiClient'
import { IProfile, IProfileNote } from '@app/Util/AppData';
import { TextArea } from '@patternfly/react-core';
import { EventListeners } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import { AppSpinner, html } from '@app/Util/AppUtil';
import { AppStateContext } from '@app/Util/AppState';

const descLimit = 4000;

interface IProfileNoteComponent {
  aboutProfileId: string
  saveButtonOnClickDoThisToo:(runnable: ()=> void) => void
}
const ProfileNoteEditor: React.FunctionComponent<IProfileNoteComponent> = ({aboutProfileId, saveButtonOnClickDoThisToo}) => {
  const descRef = React.useRef();

  const [bodyText, setBodyText] = React.useState("...loading");
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const appState = React.useContext(AppStateContext)
  const sessionProfileId = appState.sessionProfileId 

  const queryKey = ["profileNoteGet", sessionProfileId, aboutProfileId]
  const { status, data: profileNote, error, isLoading } = useQuery<IProfileNote, Error>({
    queryKey: queryKey,
    queryFn: sessionProfileId ? () => apiClient.profileNoteGet(sessionProfileId, aboutProfileId) : skipToken,
    placeholderData: keepPreviousData,  
    refetchOnWindowFocus: false,      
  });

  const mutation = useMutation({
    mutationFn: () => apiClient.profileNotePut("" + sessionProfileId, aboutProfileId, bodyText),
    onSuccess: () => {
        queryClient.invalidateQueries({queryKey: queryKey})        
    },
  });

  React.useEffect(
    () =>{
      saveButtonOnClickDoThisToo(()=> {        
        mutation.mutate()
      })
    }
    , [saveButtonOnClickDoThisToo]
  )

  //let strJson  = JSON.stringify({profilename,birthdate,height,weight,selectedChars,description })

  const saveProfile = (p: IProfile) => mutation.mutate();

  return (
    isLoading || profileNote == undefined
      ? <AppSpinner />
      : <div>
        <TextArea
                style={{ minHeight: "9em" }}
                defaultValue={profileNote.bodyText}
                maxLength={descLimit}
                autoResize
                onChange={(e,v) => setBodyText(v)}
                id="horizontal-form-exp"
                name="horizontal-form-exp"
              />
        </div>
  )
}

export { ProfileNoteEditor };

