import React from 'react'
import { Checkbox, ExpandableSection, Form, FormGroup } from "@patternfly/react-core"
import { allowedProfileCharacteristics } from "../Util/AppData"

const ProfileFilter = ({filterChars, setFilterChars, filtered, setFiltered}: {filterChars: string[], setFilterChars, filtered, setFiltered}) => {
  return (
    <ExpandableSection toggleText={filtered ? 'Filter' : 'Unfiltered'} onToggle={(e, v) => setFiltered(v)} isExpanded={filtered}>      
      <Form isHorizontal>
        {
          allowedProfileCharacteristics.map((charGroup, index) =>
            <FormGroup
              label={charGroup.category}
              //isStack
              isInline
              fieldId={"horizontal-form-checkbox-group" + index}
              hasNoPaddingTop
              role="group"
            >
              {
                charGroup.chars.map(
                  (characteristic) => {
                    const c = characteristic.toLowerCase()
                    return (
                      <Checkbox
                        key={c}
                        component={'span'}
                        id={"CHAR_" + c}
                        label={characteristic}
                        isChecked={filterChars.includes(c)}
                        onChange={
                          (e, v) => {
                            if (v) {
                              if (filterChars && !filterChars.includes(c)) {
                                //filterChars does not contain c
                                setFilterChars([...filterChars, c])
                              } else {
                                //Do Nothing as fitlerChars already contains c
                              }
                            } else {
                              setFilterChars(filterChars.filter(val => val != c))
                            }
                          }
                        }
                      />
                    )
                  }
                )
              }

            </FormGroup>
          )
        }
      </Form>
      <br/>
    </ExpandableSection>
  )
}

export { ProfileFilter }