import * as React from 'react';
import { PageSection, Text, TextContent, TextList, TextListItem, TextVariants, Title } from '@patternfly/react-core';
import { Link } from 'react-router-dom';

const AboutUs: React.FunctionComponent = () => (
  <PageSection>
     <br/> 
    <TextContent>
      <Title headingLevel="h1">AboutUs - Gaydr.net</Title>
      <Text style={{}} component='p'>All the best of class features in one community supported and managed app.</Text>
      
      <Text component='h2'>Our Values</Text>
      <TextList>
        <TextListItem>Community</TextListItem>
        <TextListItem>Safety</TextListItem>
        <TextListItem>Enjoyment</TextListItem>
        <TextListItem>Responsibility</TextListItem>
        <TextListItem>Evolution</TextListItem>
      </TextList>
     
      <Text component={TextVariants.h2}>Attributions</Text>
      <Text component='p'>We would like to express our gratitude for the many excellent open source projects proving the technology for our maps implementation, without which this site would not be possible.</Text>
      <Text>Here are some notable technologies upon which our site utilizes:</Text>
      <TextList>
        <TextListItem><Link to="https://www.openstreetmap.org/">Open Street Map</Link></TextListItem>
        <TextListItem><Link to="https://leafletjs.com/">Leaflet Js - Please support Ukraine!</Link></TextListItem>
      </TextList>
     
    </TextContent>
  </PageSection>
)

export { AboutUs };
