import * as React from 'react';
import { Button, Grid, GridItem, Stack, StackItem } from '@patternfly/react-core';
import { Like, IProfileLike } from '../Util/AppData';
import { useHistory } from 'react-router-dom';
import { useApiClient } from '../Util/ApiClient';
import { useQueryClient } from '@tanstack/react-query';
import { ProfileNoteEditor } from './ProfileNoteEditor';
import { AppStateContext } from '../Util/AppState';

const buttonStyle : React.CSSProperties = { width: "100%", color:"white", backgroundColor: "orange", fontWeight:"bold", fontSize:"18px" }

export const ProfileActionButton = ({variant = 'primary', color, textColor, text, spinnerColor, onClick, isLoading = false, disabled = false} : {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning' | 'link' | 'plain' | 'control' | 'stateful',
  color: string
  textColor? : string
  spinnerColor?: string
  text: string
  onClick?: React.MouseEventHandler,
  isLoading?: boolean,
  disabled?: boolean
}) => {
  const id= "action_button_" + (Math.random() * 1000).toFixed(0)
  const tcStyle = textColor ? { color: textColor } : {}
  return (
    <>
      { spinnerColor && <style> #{id} .pf-v6-c-spinner__path {"{"} stroke:{spinnerColor}; {"}"}</style> }
      <Button
        id={id} 
        style={{
          ...buttonStyle, 
          ...tcStyle, 
          backgroundColor: color
        }} 
        onClick={onClick}       
        disabled={disabled}
        isLoading={isLoading}
        spinnerAriaLabel='Loading...'
        spinnerAriaValueText = 'Loading'
        spinnerAriaLabelledBy='Hello'
        isBlock={true}
        variant={variant}
        //color={color}            
      >
        {text}
      </Button>
    </>
  )
}

interface IProfileActionButtons {
  profileId: string
  profileLikeType?: Like
  showChatProfileButton?: boolean
  showLikeButtons?: boolean
  showNotes?: boolean
  showAction?: boolean
}
const ProfileCardActionButtons = ({ profileId, profileLikeType, showChatProfileButton = false, showLikeButtons = false, showNotes : showNotesSection = false, showAction = false }: IProfileActionButtons) => {
  const [showNotes, setShowNotes] = React.useState(false)
  const [notesIsDraft, setNotesIsDraft] = React.useState(false)
  const [saveNotesFn, setSaveNotesFn] = React.useState(Function)
  
  const [showAdminActions, setShowActions] = React.useState(false)
  
  const history = useHistory()
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  const appState = React.useContext(AppStateContext)
  const sessionProfileId = appState.sessionProfileId

  const likeButtonHandler = () => {
    const newLike = profileLikeType == Like.LIKE ? Like.DISLIKE : Like.LIKE
    const profileLike: IProfileLike = {
      likerProfileId: sessionProfileId + "",
      likedProfileId: profileId + "",
      likeType: newLike,
      tsCreated: new Date()      
    }
    const p = apiClient.profileLikePut(profileLike)
    p.then(() => {
      queryClient.setQueryData(["profileLikesByLikerGet", sessionProfileId], (profileLikes : IProfileLike[]) => {
        let r = profileLikes.filter(pl => pl.likedProfileId != profileId)
        r.push(profileLike)        
        return r
      })
      queryClient.invalidateQueries({queryKey: ["profileLikesGet", sessionProfileId]});      
    })    
  }
  
  let onSaveRunnables: Function[] = []
  const onSaveAlsoRun = (r: Function) => onSaveRunnables = [...onSaveRunnables, r]

  return (    
    <Stack hasGutter>      
      { showChatProfileButton &&
        <StackItem>
          <ProfileActionButton color="darkblue" text="Chat / Profile" onClick={() => { history.push('/profile?id=' + profileId); }} />            
        </StackItem>
      }
      { showLikeButtons &&
        <StackItem>
          <ProfileActionButton color="darkblue" text={profileLikeType != Like.LIKE ? "Like" : "Remove Like"} onClick={likeButtonHandler} />
        </StackItem>
      }
      { showNotesSection &&
        (
          !showNotes
          ? 
            <StackItem>
              <ProfileActionButton color="lightblue" textColor="black" text={"Show Notes"} onClick={() => { setShowNotes(true)}} />        
            </StackItem>
          : 
            <StackItem>
              <Grid hasGutter>
                <GridItem span={12}>
                  <ProfileNoteEditor aboutProfileId={profileId} saveButtonOnClickDoThisToo={ onSaveAlsoRun } />
                </GridItem>          
                <GridItem span={6}>
                  <ProfileActionButton color="orange" text="Hide Notes" onClick={() => { setShowNotes(false)}} />            
                </GridItem>
                <GridItem span={6}>
                  <ProfileActionButton color="darkred" text="Save Notes" onClick={() => { onSaveRunnables.forEach(r => r()) }} />            
                </GridItem>
              </Grid>
            </StackItem>
        )
      }

      {
        showAction && 
        (
          !showAdminActions
          ? 
            <StackItem>
              <ProfileActionButton color="gray" text="Action" onClick={() => setShowActions(true)} />
            </StackItem>
          : 
            <StackItem>    
              <Grid hasGutter>
                <GridItem span={6}>
                  <ProfileActionButton color="orange" text="Block" onClick={() => {}} />            
                </GridItem>
                <GridItem span={6}>
                  <ProfileActionButton color="darkred" text="Report Violation" onClick={() => {}} />            
                </GridItem>
              </Grid>
          </StackItem>
        )
      }
    </Stack>
  );
};

export { ProfileCardActionButtons, IProfileActionButtons }