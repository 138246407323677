import * as React from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useHistory } from 'react-router-dom'
import { IContactUsMessage, IMessage, IProfile, IProfileLike, IProfileBrief as IProfileBrief, IProfileNote, IProfilePicLike, IProfilePosition, IProfilePositionAggregate, ISettings, IVersion, Like } from './AppData'
import { AppStateContext } from './AppState'
import { useCookie } from 'react-use'
import { useQueryClient } from '@tanstack/react-query'
import { LocationReportingContext } from './LocationReportingContext'

//Very Important
require('json.date-extensions')  //https://github.com/RickStrahl/json.date-extensions

const useMyAxios = () => {
  const [geoCookie, updateGeoCookie, deleteGeoCookie] = useCookie("geo")
  const locationReporting = React.useContext(LocationReportingContext)
  ;(JSON as any).useDateParser()

  //console.log("useMyaxios")
  const appStateContext = React.useContext(AppStateContext)
  const history = useHistory()
  //console.log(history? "history is good" : "bad")
 
  const ax = axios.create({
    //baseURL: 'https://some-domain.com/api/',
    //timeout: 1000,
    //headers: {'X-Custom-Header': 'foobar'},  
  })
  
  ax.interceptors.request.use( config => {  
    const r : any = config
    //console.log(config)
    r.meta = r.meta || {}
    r.meta.requestStartedAt = new Date().getTime()
    return r
  })
  ax.interceptors.response.use(    
    res => {
      //console.log(res)
      const r : any = res
      r.durationInMs = new Date().getTime() - r.config.meta.requestStartedAt
      if (res.headers['set-cookie']) {
        console.log("Server Response Cookie Recieved", res.headers['set-cookie'])
      }
      return r
    },
    err => {
      err.durationInMs = new Date().getTime() - err.config.meta.requestStartedAt   
      console.log(err)   
      if ( err.response.status == 401 && err.response.config.url != "/api/auth" ) {
        console.log("Recieved Status 401 Unauthorized")
        locationReporting?.setPauseReporting(true)
        console.log("Redirecting to login")
        history.push("/login")
      }
      return Promise.reject(err)
    }
  )
  return ax
}


const useApiClient = () => {
  const [geoCookie, updateGeoCookie, deleteGeoCookie] = useCookie("geo")
  const axios = useMyAxios()
  const appState = React.useContext(AppStateContext)  
  const queryClient = useQueryClient()

  let history = useHistory()

  //Dont do this, violates example from UseQueryExample
  function easyGet(url : string, config : AxiosRequestConfig, fnData: (response: AxiosResponse<any, any>) => void, fnError: (error: any) => void, fnFinally: () => void) {
    return axios.get(url, config)
    .then(function (response) { fnData(response) })
    .catch(function (error) { fnError(error) })
    .finally(function () { fnFinally() })
  }

  const ping = async () => {
    const res = await axios.get('/api/ping').catch((e) => console.log())    
    return {}
  }
  const version = async () => {
    const res = await axios.get('/api/version')    
    return res.data as IVersion
  }
  const authPost = async (username: string, password: string) => {
    const formData = new FormData()
    formData.append("username", username)
    formData.append("password", password)
    const res = axios.post('/api/auth', formData, {
      headers: {
        'Content-Type': "multipart/form-data"    
      },
      maxRedirects: 0
    })
    //res.then(r => r.data).catch(e=> console.log(e))
    return res    
  }
  const settingsGet = async () => {
    const res = await axios.get('/api/settings')
    return res.data as ISettings
  }
  const settingsPut = async (settings :ISettings) => {
    const res = await axios.put('/api/settings', settings)
    return res.data as ISettings
  }
  const contactUsMessagePost = async (contactUs: IContactUsMessage) => {
    const res = await axios.post('/api/contactus-messages', contactUs)
    return res
  }
  const profilesFind = async () => {
    const res = await axios.get('/api/profiles')
    return res.data as IProfile[]
  }
  const profilePut = async (profile :IProfile) => {
    const res = await axios.put('/api/profile', profile)
    return res.data as IProfile
  }
  const profileGetById = async (id : string) => {
    const res = await axios.get('/api/profile/' + id)
    return res.data as IProfile
  }
  const profileLikePut = async (profileLike : IProfileLike ) => {    
    const res = await axios.put('/api/profile-like', profileLike)
    return res.data as string
  }
  const profileLikeGet = async (likerProfileId: string, likedProfileId: string) => {    
    const res = await axios.get('/api/profile-like?likerProfileId=' + likerProfileId + "likedProfileId=" + likedProfileId)
    return res.data as IProfileLike
  }
  const profileLikesByLikerGet = async (likerProfileId : string) => {
    const res = await axios.get('/api/profile-likes/by-liker/' + likerProfileId)
    return res.data as IProfileLike[]
  }
  const profileLikesByLikedGet = async (likedProfileId : string) => {
    const res = await axios.get('/api/profile-likes/by-liked/' + likedProfileId)
    return res.data as IProfileLike[]
  }
  const profilePicLikesByLikerGet = async (likerProfileId: string) => {
    const res = await axios.get('/api/profile-pic-likes/by-liker-profileid/' + likerProfileId)
    return res.data as IProfilePicLike[]
  }
  const profilePicLikesByLikedGet = async (likedProfileId: string) => {
    const res = await axios.get('/api/profile-pic-likes/by-liked-profileid/' + likedProfileId)
    return res.data as IProfilePicLike[]
  }
  const profilePicLikePut = async (profilePicLike: IProfilePicLike) => {
    const res = await axios.put('/api/profile-pic-like', profilePicLike)
    return res.data as string
  }
  const profileNoteGet = async (authorProfileId: string, aboutProfileId: string) => {
    const res = await axios.get('/api/profile-note', {params: {authorProfileId, aboutProfileId}})
    return res.data as IProfileNote
  }
  const profileNotePut = async (authorProfileId: string, aboutProfileId: string, bodyText: string) => {    
    const res = await axios.put('/api/profile-note', { authorProfileId: authorProfileId, aboutProfileId: aboutProfileId, bodyText: bodyText })
    return "Success"
  }
  const profilePositionPut = async (profilePosition: IProfilePosition) => {    
    const res = await axios.put('/api/profile-position/' + profilePosition.profileId, profilePosition)
    return "Success"
  }
  const profileBriefsNearestGet = async (page: number, size: number) => {
    const res = await axios.get(`/api/profile-briefs/nearest?page=${page}&size=${size}`)
    return res.data as IProfileBrief[]
  }
  const profileBriefsByBoundsGet = async (latMin: Number, lonMin: Number, latMax: Number, lonMax: Number, page: number = 0, size: number = 500) => {
    const url = `/api/profile-briefs/by-bounds?latMin=${latMin}&lonMin=${lonMin}&latMax=${latMax}&lonMax=${lonMax}&page=${page}&size=${size}`;
    (JSON as any).useDateParser()  //NoArg Required
    const res = await axios.get(url)
    //Always Use it as parallel calls get confused sometimes //(JSON as any).useDateParser(false)
    res.data.forEach(element => {element.tsUpdated = new Date(element.tsUpdated)});
    return res.data as IProfileBrief[]
  }
  const profileBriefsBySectorGet = async (sector: string, page: number = 0, size: number = 500) => {
    const url = `/api/profile-briefs/by-sector?sector=${sector}&page=${page}&size=${size}`;
    (JSON as any).useDateParser()  //NoArg Required
    const res = await axios.get(url)
    //Always Use it as parallel calls get confused sometimes //(JSON as any).useDateParser(false)
    res.data.forEach(element => {element.tsUpdated = new Date(element.tsUpdated)});
    return res.data as IProfileBrief[]
  }
  const profilePositionAggregateGet = async () => {
    const url = `/api/profile-positions/aggregates`;
    (JSON as any).useDateParser()  //NoArg Required
    const res = await axios.get(url)
    //Always Use it as parallel calls get confused sometimes //(JSON as any).useDateParser(false)
    res.data.forEach(element => {element.tsUpdated = new Date(element.tsUpdated)});
    return res.data as IProfilePositionAggregate[]
  }
  const messagePost = async (message :IMessage) => {
    const res = await axios.post('/api/messages', message)
    return res.data as IMessage
  }
  const messagesWithGet = async (perspectiveProfileId : string, otherProfileId: string, sinceMessageId?: string, beforeMessageId?: string) => {
    const url = "/api/messages/with/" + perspectiveProfileId + "/" + otherProfileId + ( sinceMessageId ? "?sinceMessageId=" + sinceMessageId : "") + ( beforeMessageId ? "?beforeMessageId=" + beforeMessageId : "");
    (JSON as any).useDateParser()  //NoArg Required
    const res = await axios.get(url);
    //Always Use it as parallel calls get confused sometimes //(JSON as any).useDateParser(false)
    const data = res.data as IMessage[]
    data.forEach((i, index) => { 
      i.tsCreated = new Date(i.tsCreated)
      i.tsRecipientDeleted = i.tsRecipientDeleted ? new Date(i.tsRecipientDeleted) : undefined
      i.tsRecipientRead = i.tsRecipientRead ? new Date(i.tsRecipientRead) : undefined
      i.tsSenderDeleted = i.tsSenderDeleted ? new Date(i.tsSenderDeleted) : undefined
    })
    //data = (JSON as any).parseWithDate(JSON.stringify(data))
    return data as IMessage[]
  }
  const messagesMostRecentWith = async (myProfileId: string, sinceMessageId?: string, beforeMessageId?: string) => {
    const url = '/api/messages/most-recent-with/' + myProfileId + ( sinceMessageId ? "?sinceMessageId=" + sinceMessageId : "") + ( beforeMessageId ? "?beforeMessageId=" + beforeMessageId : "");
    (JSON as any).useDateParser()  //NoArg Required
    const res = await axios.get(url);
    //Always Use it as parallel calls get confused sometimes //(JSON as any).useDateParser(false)    
    const d = res.data
    return d as IMessage[]    
  }
  const messagesBySectorGet = async (sector: string, sinceMsAgo: number = 0) => {
    const url = `/api/messages/by-sector?sector=${sector}&sinceMsAgo=${sinceMsAgo}`;
    (JSON as any).useDateParser()  //NoArg Required
    const res = await axios.get(url)
    //Always Use it as parallel calls get confused sometimes //(JSON as any).useDateParser(false)
    res.data.forEach(element => {element.tsCreated = new Date(element.tsCreated)});
    return res.data as IMessage[]
  }

  const forceLogout = () => {
    appState.auth.logout()
    console.log("Redirecting to /login")
    history.push("/login")
  }
  const sorryPage = () => {
    history.push("/sorry")
  }

  //Below this line is Native Fetch for Reference, 
  //all this should be elminated and migrated to axios usage
  const apiFetch = (url : string, fnData : Function, fnError : Function, method : string, body?: string | ReadableStream<any> | Blob | ArrayBufferView | ArrayBuffer | FormData | null | undefined, headers?: HeadersInit | null | undefined) => {
    //headers : { 'Content-Type' : "application/json"}
    //    const redirect_type = "manual" //"error"  // "follow"
    const options : RequestInit = {redirect : 'follow', method : method}
    if (headers != null) options.headers = headers
    if (body != null) options.body = body
    fetch(url, options)
    .then(
      res => {
        //if (res.status == 302) {forceLogout() return "Bad Auth"} //Sadly this will never happen, Bad API!!!
        if (res.redirected && res.url == "/login") {
          console.log("Forcing Logout")
          forceLogout()
        }

        if (res.status.toString().startsWith("5")) {sorryPage(); return "Sorry"}

        if (res.headers.get('Content-Type')?.startsWith("application/json"))      
          return res.json()
        else 
          return res.text()
      }, 
      err => {      
        //When error or manual 302 redirect will land here, but how do we know if this is for auth error?
        console.group("apiFetch error")
        console.error(err)
        console.groupEnd()
        fnError(err)
      }
    )
    .then(
      data =>{ 
        //Log when Dev mode
        console.log(data)
        fnData(data)
      },
      err => {
        //console.log(err)
        fnError(err)
      }
    )    
  }
  const Api = { 
    get: (url : string, fnData : Function, fnError : Function) => { apiFetch(url, fnData, fnError, "GET", null, {'Content-Type':"application/json"}) },
    post: (url : string, json :object, fnData : Function, fnError : Function) => { apiFetch(url, fnData, fnError, "POST", JSON.stringify(json), {'Content-Type':"application/json"}) },
    put: (url : string, json :object, fnData : Function, fnError : Function) => { apiFetch(url, fnData, fnError, "PUT", JSON.stringify(json), {'Content-Type':"application/json"}) },
    postForm : (url : string, formData : FormData, fnData : Function, fnError : Function) => { apiFetch(url, fnData, fnError, "POST", formData, null) },
    ping,
    version,
    authPost,
    settingsGet,
    settingsPut,   
    contactUsMessagePost,  
    profilesFind,
    profileGetById,
    profilePut,
    profileLikesByLikerGet,
    profileLikesByLikedGet,
    profileLikePut,
    profilePicLikesByLikerGet,
    profilePicLikesByLikedGet,
    profilePicLikePut,
    profileNoteGet,
    profileNotePut,
    profilePositionPut,
    profilePositionAggregateGet,
    profileBriefsNearestGet,
    profileBriefsByBoundsGet,
    profileBriefsBySectorGet,
    messagesMostRecentWith,
    messagePost,
    messagesWithGet,
    messagesBySectorGet
  }
  return Api
}
const profilePicUrl = "/api/profile-pic"
export { useApiClient, profilePicUrl }
