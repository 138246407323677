
import React from 'react';
import { NavLink, useLocation, useHistory, Router } from 'react-router-dom';
//import logo from '@patternfly/react-core/src/components/LoginPage/examples/brandImgColor2.svg';                       
import { Button, PageSection,  Title } from '@patternfly/react-core';
import { AppStateContext } from '../Util/AppState';

export const AppLogout: React.FunctionComponent = (props) => {
  const history = useHistory();
  const appState = React.useContext(AppStateContext);

  React.useEffect(()=>{
    console.log(appState)
    appState.auth.logout()
    history.push("/login");    
  }, []);

  const onLoginButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {  
    history.push("/login");
  }

  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">Logout</Title>
      <div>
          You are being logged out.<br />
          <Button onClick={onLoginButtonClick}>Login Again</Button>
      </div>
    </PageSection>
  );

};
