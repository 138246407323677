import * as React from 'react';
import { useProfileBriefsByPosition } from '@app/Util/AppDataPositionQueries';
import { PageSection } from '@patternfly/react-core';
import { LatLngBounds } from 'leaflet';
import { useHistory } from 'react-router-dom';
import { AppSpinner, colloquialDistance, profilePicSteppedWidthApiUrl } from '../../Util/AppUtil';
import { LocationReportingContext } from '../../Util/LocationReportingContext';
import { ITile, TilesGrid } from '../../Widgets/TilesGrid';
//TODO  Implement Inf Scrolling and Filtering 
//https://codesandbox.io/s/infinite-scrolling-useref-g4fgz?file=/src/App.js

const REFETCH_INTERVAL_SECS = 120

interface ITilesPage { }
const TilesPage: React.FunctionComponent<ITilesPage> = (props) => {
  const history = useHistory()
  const locationReporting = React.useContext(LocationReportingContext)
  const rll = locationReporting.reportingLatLng

  const bounds = new LatLngBounds([rll.lat -1, rll.lng - 1],[rll.lat + 1, rll.lng + 1])
  const { profileBriefs, numSectorsExceeded, pending } = useProfileBriefsByPosition({bounds, tooManySectors: 20, refetchIntervalMs: REFETCH_INTERVAL_SECS * 1000})

  //const { mapMarkers, data, fetchNextPage, hasNextPage, isFetching, isLoading } = useNearestMapMarkers({pageSize: 30})
  //const [loadMoreElement, entry] = useIntersectionObserver({ threshold: 0, root: null, rootMargin: "50%" });

  const profileBriefsByDistance = profileBriefs
    .map(pb => {
      let collDist = colloquialDistance(pb.lat, pb.lon, rll.lat, rll.lng)
      return { ...pb, collDist }
    })
    .sort((v1, v2) => v1.collDist.distance_m <= v2.collDist.distance_m ? -1 : 1)
  
  const tiles = profileBriefsByDistance.map(pb => {
    const tile : ITile = { 
      uid: pb.profileId,
      imageUrlOfWidth:(width: number) => profilePicSteppedWidthApiUrl(pb.profilePicIds[0], width),
      onClick:() => history.push("/profile?id=" + pb.profileId),
      topRightLabelText: "" + pb.collDist.colloquial_imperial,
      bottomRightLabel: pb.profileName
    }
    return tile;
  })

  //console.log("Rendering Search");
  const widths = { default: "33%", sm: '33%', md: '25%', lg: '20%', xl: '10%', "2xl": '10%' }
  function fetchNextPage(): void {
    throw new Error('Function not implemented.');
  }
  const hasNextPage = false

  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      {/* <>Rendered Search : { JSON.stringify({ts: Date.now(), isFetched, isLoading, dataLe: mapMarkers?.length}) }</> */}
      {pending || !profileBriefs
        ? <AppSpinner />
        :
        <TilesGrid tiles={tiles} requestMore={fetchNextPage} hasNextPage={hasNextPage} footerText='near you'/>        
      }
    </PageSection>
  )
}


export { TilesPage };
