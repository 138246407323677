import { useQuery } from "@tanstack/react-query";
import { BoundsLiteral, LatLng } from "leaflet";
import { IProfilePositionAggregate } from "@app/Util/AppData";
import React from "react";
import { Rectangle, SVGOverlay } from "react-leaflet";
import { useApiClient } from "../../Util/ApiClient";
import { LatLngBounds } from "leaflet";
import { border, padding } from "@mui/system";

const AGGREGATE_POSITION_FONT_SIZE= "24pt"

const MapMarkerProfilePositionAggregates = ({bounds} : {bounds: LatLngBounds} ) => {
  let startTime = new Date().getTime()
    
  //console.log({"ProfilePositionAggregateMapMarkers.Bounds": bounds})

  const apiClient = useApiClient()
  const { isPending, data: profilePositionAggregates } = useQuery({
    queryKey: ["profilePositionAggregateGet"],
    queryFn: () => apiClient.profilePositionAggregateGet(),  
  })

  let aggregates : IProfilePositionAggregate[] | undefined
  if (profilePositionAggregates && profilePositionAggregates?.length > 10) {
    aggregates = ((ppas) => {
      let cummulatedPpas = new Map<String, IProfilePositionAggregate>()
      ppas.forEach(ppa => {
        //let divisor = 10
        let divisorLon = Math.floor(bounds.getEast() - bounds.getWest()) / 4
        //let divisorLat = Math.floor(bounds.getEast() - bounds.getWest()) / 6
        let divisorLat = Math.floor(divisorLon * 2 / 3)
        if (divisorLat == 0) divisorLat = 180
        if (divisorLon == 0) divisorLon = 180
        let centerLat = (Math.floor(ppa.centerLat / divisorLat) * divisorLat) + (ppa.deltaLat / 2)
        let centerLon = (Math.floor(ppa.centerLon / divisorLon) * divisorLon) + (ppa.deltaLon / 2)
        let sectorkey = centerLat + ":" +  centerLon
        //console.log({centerLat: ppa.centerLat, centerLon: ppa.centerLon, sectorkey})

        let cummulatedPpa = cummulatedPpas.get(sectorkey)
        if (cummulatedPpa == null) {
          cummulatedPpa = {
            centerLat,
            centerLon,
            deltaLat: divisorLat,
            deltaLon: divisorLon,
            count: 0 
          }
          cummulatedPpas.set(sectorkey, cummulatedPpa)
        }

        cummulatedPpa.count += ppa.count
      })
      return [...cummulatedPpas.values()]      
    }) (profilePositionAggregates);    
  } else {
    aggregates = profilePositionAggregates
  }
  aggregates = aggregates?.filter(ppa => bounds.contains([ppa.centerLat, ppa.centerLon]))
  //console.log(profilePositionAggregates?.length)
  console.log({ MapMarkerProfilePositionAggregatesRenderTime : (new Date().getTime() - startTime) + "ms", "aggregates?.length": aggregates?.length})
  
  return (
    <>
      {
        isPending && "Loading profile aggregate counts..."
      }
      {
        aggregates && aggregates.map(ppa => {
          try {
            let sw = new LatLng(ppa.centerLat - (ppa.deltaLat / 2.0), ppa.centerLon - (ppa.deltaLon / 2.0))
            let ne = new LatLng(ppa.centerLat + (ppa.deltaLat / 2.0), ppa.centerLon + (ppa.deltaLon / 2.0))
            let bounds = new LatLngBounds(sw, ne)
            return (
              <div key={ppa.centerLat + "," + ppa.centerLon}>
                <Rectangle bounds={bounds} pathOptions={{stroke: false, color: "#333", opacity: 0.1}} >
                 </Rectangle> 
                <SVGOverlay attributes={{ stroke: 'lightgray'}} bounds={bounds}>
                  <text x="50%" y="50%" textAnchor="middle" stroke="white" fill="white" style={{padding: '1em', borderRadius: "1em", border: "10px solid white", fontSize: AGGREGATE_POSITION_FONT_SIZE, fontWeight:"400", fontFamily:"Helvetica, Arial, sans-serif"}}>
                    {ppa.count}
                  </text>              
                </SVGOverlay>           
              </div>
            )
          } catch (e) {
            console.log(e)
            return null
          }
        })
      }
    </>
  )
}
export { MapMarkerProfilePositionAggregates };
