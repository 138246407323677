import { Divider, NavItemSeparator, PageSection, Stack, StackItem, Title } from '@patternfly/react-core';
import * as React from 'react';
import './ConversationsListPage.css'
import { ConversationsListPageItem } from './ConversationsListPageItem';

interface ICardList { }

const NotificationsPage: React.FunctionComponent<ICardList> = (props) => {


  return (
    <PageSection className="ConversationsListPage" height="100%" width="100%" style={{position:"relative",margin:0, padding:0}}>
      <Title headingLevel="h1" size="lg">
        Notifications
      </Title>      
      <Divider style={{margin:"1em 0"}} />
    </PageSection>

  )
}

export { NotificationsPage };
