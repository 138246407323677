import { GeoLocationSensorState } from "react-use/lib/useGeolocation";
import { LatLng, LatLngBoundsExpression, LatLngExpression } from 'leaflet';

const cities = [
  {
    "city": "Phoenix",
    "latitude": 33.4484,
    "longitude": -112.074
  },
  {
    "city": "Seattle",
    "latitude": 47.6062,
    "longitude": -122.3321
  },
  {
    "city": "New York",
    "latitude": 40.7128,
    "longitude": -74.006
  },
  {
    "city": "Los Angeles",
    "latitude": 34.0522,
    "longitude": -118.2437
  },
  {
    "city": "Chicago",
    "latitude": 41.8781,
    "longitude": -87.6298
  },
  {
    "city": "Houston",
    "latitude": 29.7604,
    "longitude": -95.3698
  },
  {
    "city": "Philadelphia",
    "latitude": 39.9526,
    "longitude": -75.1652
  },
  {
    "city": "San Antonio",
    "latitude": 29.4241,
    "longitude": -98.4936
  },
  {
    "city": "San Diego",
    "latitude": 32.7157,
    "longitude": -117.1611
  },
  {
    "city": "Dallas",
    "latitude": 32.7767,
    "longitude": -96.797
  },
  {
    "city": "San Jose",
    "latitude": 37.3382,
    "longitude": -121.8863
  },
  {
    "city": "Austin",
    "latitude": 30.2672,
    "longitude": -97.7431
  },
  {
    "city": "Jacksonville",
    "latitude": 30.3322,
    "longitude": -81.6557
  },
  {
    "city": "Fort Worth",
    "latitude": 32.7555,
    "longitude": -97.3308
  },
  {
    "city": "Columbus",
    "latitude": 39.9612,
    "longitude": -82.9988
  },
  {
    "city": "San Francisco",
    "latitude": 37.7749,
    "longitude": -122.4194
  },
  {
    "city": "Charlotte",
    "latitude": 35.2271,
    "longitude": -80.8431
  },
  {
    "city": "Indianapolis",
    "latitude": 39.7684,
    "longitude": -86.1581
  },
  {
    "city": "Seattle",
    "latitude": "47.6062",
    "longitude": "-122.3321"
  },
  {
    "city": "Denver",
    "latitude": "39.7392",
    "longitude": "-104.9903"
  },
  {
    "city": "Washington DC",
    "latitude": "38.9072",
    "longitude": "-77.0369"
  },
  {
    "city": "Boston",
    "latitude": "42.3601",
    "longitude": "-71.0589"
  },
  {
    "city": "Nashville",
    "latitude": "36.1627",
    "longitude": "-86.7816"
  },
  {
    "city": "El Paso",
    "latitude": "31.7907",
    "longitude": "-106.4233"
  },
  {
    "city": "Detroit",
    "latitude": "42.3314",
    "longitude": "-83.0458"
  },
];

function getRandomCityLatLng() : LatLng {
  const x = (Math.random() - .5) * .1;
  const y = (Math.random() - .5) * .1;
  //const randIndex = (Math.random() * cities.length).toFixed(0);
  const randIndex = 0
  const city = cities[randIndex];
  return new LatLng(Number(city.latitude) + x, Number(city.longitude) + y)
}
function getRandomGeolocationSensorState(): GeoLocationSensorState {
  const ll = getRandomCityLatLng();
  const geolocationPosition: GeoLocationSensorState = {
    loading: false,
    accuracy: 0,
    altitude: 0,
    altitudeAccuracy: 0,
    heading: 0,
    speed: 0,
    latitude: ll[0],
    longitude: ll[1],
    timestamp: 0,
  }
  return geolocationPosition;
}

export { getRandomCityLatLng, getRandomGeolocationSensorState }