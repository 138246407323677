import * as React from 'react';
import { CubesIcon } from '@patternfly/react-icons';
import {
  Button,
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateFooter,
  EmptyStateVariant,
  PageSection,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core';
import { AppContextInfo } from './AppContextInfo'
import { useApiClient } from '@app/Util/ApiClient';
import { useQuery } from '@tanstack/react-query';

let SupportPage = () => {
  return (
    <PageSection>
        <Text component={TextVariants.h2}>FAQ</Text>
        <Text component={TextVariants.h3}>What version do I report a problem with?</Text>
        <Text component='p'>
          <AppContextInfo />  
        </Text>      
      {/* 
      <EmptyState variant={EmptyStateVariant.full} titleText="Empty State (Stub Support Module)" icon={CubesIcon} >
        <EmptyStateBody>
          <TextContent>
            <Text component="p">
              This represents an the empty state pattern in Patternfly 4. Hopefully it&apos;s simple enough to use but
              flexible enough to meet a variety of needs.
            </Text>
            <Text component={TextVariants.small}>
              This text has overridden a css component variable to demonstrate how to apply customizations using
              PatternFly&apos;s global variable API.
            </Text>
          </TextContent>
        </EmptyStateBody><EmptyStateFooter>
        <Button variant="primary">Primary Action</Button>
        <EmptyStateActions>
          <Button variant="link">Multiple</Button>
          <Button variant="link">Action Buttons</Button>
          <Button variant="link">Can</Button>
          <Button variant="link">Go here</Button>
          <Button variant="link">In the secondary</Button>
          <Button variant="link">Action area</Button>
        </EmptyStateActions>
      </EmptyStateFooter></EmptyState>
      */}
    </PageSection>
  )
};

export { SupportPage };
